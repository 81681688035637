import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  persistToken(token: any) {
    sessionStorage.setItem("bearer_token", token);
  }

  getToken() {
    return sessionStorage.getItem("bearer_token");
  }

	persistUserId(id: any) {
    sessionStorage.setItem("userId", id);
	}

  persistUserEmail(emailid: any) {
    sessionStorage.setItem("emailid", emailid);
	}

  getUserEmail():string {
		return sessionStorage.getItem("emailid") || "";
	}

	getUserId() {
		return sessionStorage.getItem("userId") || "";
	}

  getPortal() {
    return sessionStorage.getItem("portal")?.toUpperCase() || "";
  }

}
