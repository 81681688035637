<div class="site-sequencer-container">
    <div class="page-header sequence-builder-header" [ngClass]="{'sequence-logs-header': isSequenceTabSelected}"
    *ngIf="builderMode === 'sequence_builder'" id="site-sequencer-header">
        <div class="pull-left" [ngClass]="{'sequence-logs-header-inner': isSequenceTabSelected}">
            <div>
                <!-- Site selection dropdown -->
                <app-select-dropdown [formFieldClass]="'siteFormField'" [placeholderLabel]="initSiteName"
                    [(ngModel)]="siteId" [data]="sites" [displayKey]="'dis'" [valueKey]="'id'"
                    (valueChanged)="getSelectedSite($event)"></app-select-dropdown>
                
                <!-- Search input field for dry run sequence logs modal -->
                <div class="search-container scope-list-search" *ngIf="isSequenceTabSelected">
                    <mat-form-field class="search-input">
                        <input matInput placeholder="Search" #searchTextField (keyup)="searchSequenceLogs(searchTextField?.value)"
                            autocomplete="off" />
                        <i class="fas fa-search" matPrefix aria-hidden="true"></i>
                    </mat-form-field>
                </div>
            </div>

            <!-- Log level type indicator for dry run sequence logs modal -->
            <div class="header-icons-container" *ngIf="isSequenceTabSelected">
                <app-error-type-legends></app-error-type-legends>
            </div>
        </div>
       
        <!-- Category level (Cloud, CCU, Device) tabs -->
        <div class="level-tabs-container" *ngIf="selectedSite && builderMode === 'sequence_builder' && !isSequenceTabSelected">
            <div class="navigation-tabs">
                <mat-tab-group class="level-tabs" animationDuration="0ms" fitInkBarToContent="true"
                    [(selectedIndex)]="categoryMenu.activeTabIndex" (selectedTabChange)="toggleCategoryLevelTab($event)">
                    <ng-container *ngFor="let levelTab of categoryMenu.tabs">
                        <mat-tab 
                            [label]="levelTab.label" 
                            matTooltip="{{levelTab.label}}" 
                            matTooltipPosition='above'>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>
        </div>
       
        <div class="pull-right p-r-10" *ngIf="!isSequenceTabSelected">
            <div class="inner-container" *ngIf="showSequenceActionButtons">
                <button mat-button class="sequence-lib-btn" color="primary"
                    [matMenuTriggerFor]="menu" (menuOpened)="setUpdatedFunctionsBlockData()" (click)="clearFilterSearch(); $event.stopPropagation();" id="custom-block-btn">
                    SEQUENCE LIBRARY
                </button>
    
                <mat-menu #menu="matMenu" xPosition="before" [hasBackdrop]="false">
                    <ul (click)="$event.stopPropagation();" class="dropdown-menu-ul" id="custom-blocks-dropdown">
                        <div class="p-l-12 p-r-12 p-t-12">
                            <mat-form-field [style.width.px]=275 class="functions-popup-input" id="custom-block-search-input">
                                <input matInput type="text" #filterSearch class="font-size13" autocomplete="off" placeholder="Search for Custom Blocks"
                                (keyup)="searchCustomBlocksOnDropDownList(filterSearch.value)" (keydown)="$event.stopPropagation()"  id="custom-block-search-input-field" />
                                <div matSuffix>
                                    <i class="fas fa-times icon-style p-r-10 cross-icon"
                                        (click)="filterSearch.value=''; clearFilterSearch();"
                                        *ngIf="filterSearch.value.length > 0" aria-hidden="true" id="clear-search-icon"></i>
                                </div>
                            </mat-form-field>
                        </div>
                        <ng-container id="custom-blocks-container">
                            <div class="list-menu-functions">
                                <ng-container [ngTemplateOutlet]="CustomBlocksDropdown"
                                    [ngTemplateOutletContext]="{ list: dropdownFunctionsList?.createdByMeFunctions, name: 'Created by me', type: 'createdByMeFunctions' }"
                                    id="created-by-me-container">
                                </ng-container>
                                <ng-container [ngTemplateOutlet]="CustomBlocksDropdown"
                                    [ngTemplateOutletContext]="{ list: dropdownFunctionsList?.createdByOtherFunctions, name: 'Shared by others', type: 'createdByOtherFunctions' }"
                                    id="shared-by-others-container">
                                </ng-container>
                            </div>
                            <div class="create-btn" id="create-new-custom-block-btn" (click)="navigateToLibraryBuilder()">CREATE NEW CUSTOM BLOCK </div>
                        </ng-container>
                    </ul>
    
                </mat-menu>
                
                <ng-template #CustomBlocksDropdown let-list="list" let-name="name" let-type="type">
                    <app-accordion [blockTitle]="name" [isOpen]="dropdownAccordion[type]" (accordianState)="dropdownListAccordion(type)">
                        <ng-container [ngTemplateOutlet]="functionBlocksList"
                            [ngTemplateOutletContext]="{list:list,type:type}">
                        </ng-container>
                    </app-accordion>
                </ng-template>
              
                <ng-template #functionBlocksList let-name="name" let-list="list" let-type="type">
                    
                    <ng-container *ngIf="list?.length">
                        <li class="div-bgcolor list-menu-function" (click)="$event.stopPropagation();" *ngFor="let item of list; let i=index"
                            aria-hidden="true" [ngClass]="{'dragging': isDraggingFunctionBlock}"
                            cdkDropList
                            [cdkDropListData]="sequencerFunctionCollection"
                            (cdkDropListDropped)="drop($event, item)">
                            <ng-container>
                                <div class="block-text-height" 
                                    (mouseenter)="onOptionHover(item)"
                                    (mouseleave)="onMouseOut()" placement="left" id="block-text-height"
                                    cdkDrag
                                    (cdkDragStarted)="handleCursorType('dragStarted', $event)"
                                    (cdkDragEnded)="handleCursorType('dragEnded', $event)"
                                    (cdkDragMoved)="handleCursorType('moved', $event)"
                                    [cdkDragData]="item"
                                    [cdkDragPreviewClass]="'drag-preview'">
                    
                                    <div class="block-name-container">       
                                        <img src="../../../assets/images/drag-block.svg" class="drag-block-icon" alt="" id="drag-block-icon">                                 
                                        <div class="block-lable-name" id="block-label-name"  (mouseout)="unhoveredFunction()" (mouseover)="hoveredFunction($event)">
                                            {{item?.seqFunctionName}}
                                        </div>
                                    </div>
                                
                                    <div class="action-container" [ngClass]="type == 'createdByMeFunctions' ? 'actionItemsPosition' : ''" id="action-container">
                                        <div *ngIf="item?.noOfLikes > 0"  class="p-l-2 p-r-5 likes-text" id="likes-text">
                                            <span [matTooltip]="getLikeMultiplierCount(item?.noOfLikes) > 999 ? getLikeMultiplierCount(item?.noOfLikes) + ' Likes': ''" [matTooltipPosition]="'above'">{{likeCountFormat(item?.noOfLikes)}} {{getLikeMultiplierCount(item?.noOfLikes) == 1 ? 'Like': 'Likes'}}</span>
                                        </div>                                
                                        <div *ngIf="type !='createdByMeFunctions'" class="like drop-down-icon p-r-3" (click)="updateBlockLikeBookmark('LIKE', item)" id="like-icon">
                                            <img *ngIf="item.likedByLoggedInUser" class="like-image" src="../../../assets/images/selected-like.svg" alt="" id="liked-image">
                                            <img *ngIf="!item.likedByLoggedInUser" class="like-image" src="../../../assets/images/default-like.svg" alt="" id="default-like-image">
                                        </div>
                                        <div class="drop-down-icon p-l-2 p-r-5 bookmark" (click)="updateBlockLikeBookmark('BOOKMARK', item)" id="bookmark-icon">
                                            <img *ngIf="item.bookmarkedByLoggedInUser" class="like-image" src="../../../assets/images/bookmarksolid.svg" alt="" id="solid-bookmark-icon">
                                            <img *ngIf="!item.bookmarkedByLoggedInUser" class="like-image" src="../../../assets/images/bookmarkoutline.svg" alt="" id="regular-bookmark-icon">
                                        </div>
                                        <div class="drop-down-icon p-l-3 p-r-3 bookmark-icon" id="filter-dialog-icon">
                                            <i *ngIf="type === 'createdByMeFunctions' && item.functionId == hoveredFunctionBlock?.functionId && enableTableEdit" class="fas fa-pen edit-icon color-primary" id="edit-icon" (click)="seqFunctionActionFromFlyout('edit', item)"></i>
                                            <i *ngIf="type != 'createdByMeFunctions' && item.functionId == hoveredFunctionBlock?.functionId && enableTableEdit" class="fas fa-eye edit-icon color-primary" id="eye-icon" (click)="seqFunctionActionFromFlyout('view', item)"></i>
                                        </div>
                                    </div>
    
                                    <div *cdkDragPlaceholder class="field-placeholder"></div>
                                </div>
    
                            </ng-container>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!list?.length">
                        <div (click)="$event.stopPropagation();" id="no-blocks-display" class="p-t-10 p-l-17">
                            No Blocks to show
                        </div>
                    </ng-container>
                </ng-template>
            </div>
        </div>

    </div>
    <div class="tooltip" [ngClass]="{'visibility-show': tooltip}" id="custom-block-tooltip">
        <div class="tooltip-header entity-tooltip">
            <div class="tool-text">
                <div class="sub-text p-b-15" [id]="hoveredFunctionBlock?.why">Why: {{hoveredFunctionBlock?.why}}</div>
                <div class="sub-text p-b-15" [id]="hoveredFunctionBlock?.what">What: {{hoveredFunctionBlock?.what}}</div>
                <div class="sub-text">
                    <span>Created By: <strong>{{ownerDetails}}</strong> </span><span class="p-l-5">Last Updated: <strong>{{formatDate(hoveredFunctionBlock?.createdBy?.dateTime)}}</strong></span> 
                </div>
            </div>
        </div>
    </div>   

    <div class="page-header library-builder-header" *ngIf="builderMode === 'library_builder'">
        <div class="pull-left">
            <button mat-button class="action-btn" color="primary" (click)="navigateBackToSiteSequences()" id="back-to-sites-btn">
                Back to Site Sequences
            </button>
        </div>

        <div class="pull-right" *ngIf="!isWorkspaceReadOnly">
            <button mat-button class="action-btn" color="primary"
                *ngIf="!isBuildingNewSequenceFunction && !isDuplicatingSequenceFunction && selectedFunctionToView"
                (click)="seqFunctionActionFromFlyout('delete', selectedFunctionToView)" id="delete-sequence-btn">
                Delete Sequence
            </button>
            <button mat-button class="action-btn" color="primary" (click)="clearWorkspace()" id="clear-workspace-btn">
                Clear
            </button>
            <button mat-button class="action-btn" color="primary" *ngIf="isBuildingNewSequenceFunction"
                (click)="saveSequenceFunction()" id="create-new-sequence-btn">
                Create New Sequence
            </button>
            <button mat-button class="action-btn" color="primary" *ngIf="!isBuildingNewSequenceFunction"
                (click)="saveAndUpdateSequenceFunction()" id="update-sequence-btn">
                Update Sequence
            </button>
            <button mat-button class="action-btn" color="primary" id="duplicate-sequence-btn"
                *ngIf="!isBuildingNewSequenceFunction && !isDuplicatingSequenceFunction && selectedFunctionToView"
                (click)="duplicateSequenceFunction()">
                Create As New Sequence
            </button>
        </div>    
    
        <div class="pull-right" *ngIf="!isBuildingNewSequenceFunction && (isWorkspaceReadOnly)">
            <button mat-button class="action-btn" color="primary"
                *ngIf="selectedFunctionToView?.category != 'Shared by Others' " (click)="enableEditSequence()" id="edit-sequence-btn">
                Edit Sequence
            </button>
            <button mat-button *ngIf="isWorkspaceReadOnly" [disabled]="true" class="action-btn" color="primary"
                (click)="clearWorkspace()" id="clear-btn">
                Clear
            </button>
            <button mat-button class="action-btn" color="primary"
                *ngIf="!isDuplicatingSequenceFunction && selectedFunctionToView?.category == 'Shared by Others' "
                (click)="duplicateSequenceFunction()" id="create-new-sequence-btn">
                Create As New Sequence
            </button>
        </div>
    </div>
    <button #showHideButton class="showHideButton" [ngClass]="{'toolboxActive': showToolBox}"
        (click)="showHideToolBox(!showToolBox)" mat-fab *ngIf="showToolBoxToggleButton" id="show-tool-box">
        <i class="btn-icon" [ngClass]="this.showToolBox ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
    </button>

    <div class="main-content default-workspace">
        <div class="breadcrumbs-container" [ngClass]="{'toolboxActive': showToolBox}">
            <div class="breadcrumb-label-wrapper" *ngFor="let seq of breadcrumbs" (click)="getBlockIntoView(seq, true)">
                <span class="breadcrumb-label">
                    {{ seq.seqName }}
                </span>
            </div>
        </div>
        <div class="pull-right workspaceBtn" *ngIf="showSequenceActionButtons && builderMode === 'sequence_builder'">
            <button mat-button class="action-btn" color="primary" id="download-btn" *ngIf="categoryMenu.selectedCategory.id === 'DEVICE'" (click)="downLoadSequence()">
                Download
            </button>
            <button mat-button class="action-btn" color="primary" id="ccu_logs-btn" *ngIf="categoryMenu.selectedCategory.id === 'CCU'"
                (click)="openCCULogsModal()">
                CCU Logs
            </button>
            <button mat-button class="action-btn" color="primary" (click)="loadSavedState()" id="cancel-btn">
                Cancel
            </button>
            <button [disabled]="noChangesInWorkspace" mat-button class="action-btn" color="primary" (click)="validateAndSaveSequence()" id="save-sequence-btn">
                {{categoryMenu.selectedCategory.id === 'CLOUD' ?  'Save & Run' : 'Save'}}
            </button>
        </div>

        <div class="col-12 no-building placeholder-text"
            *ngIf="builderMode === 'sequence_builder' && placeholderMessage">
            <i class="fas fa-building fa-5x" aria-hidden="true" *ngIf="!selectedSite"></i>
            <div class="message-text">{{placeholderMessage}}</div>
        </div>
        <div class="workspace-wrapper" *ngIf="!isSequenceTabSelected && selectedSite">
            <div class="blockly-container" [id]="blocklyContainerId"></div>
            <div class="codeSnippetContainer" [ngClass]="{'expanded': isCodeBlockVisible}">
                <div class="code-snippet-inner">
                    <button #showHideCodeBlock class="showHideCodeBlock" matTooltipClass="codeBlockTooltip"
                        #tooltip="matTooltip" [matTooltip]="codeBlockTooltipText" matTooltipPosition="above"
                        (click)="showHideCodeSnippetBlock()" mat-fab>
                        <i class="btn-icon"
                            [ngClass]="isCodeBlockVisible ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
                    </button>
                    <div class="code-snippet-block">

                        <!-- <div *ngIf="isCodeBlockVisible" class="coding-language-dropdown">
                            <app-select-dropdown [formFieldClass]="'languageFormField'"
                                [placeholderLabel]="initLanguageName" [(ngModel)]="selectedLanguageId"
                                [data]="languages" [displayKey]="'name'" [valueKey]="'id'"></app-select-dropdown>
                        </div> -->

                        <ngx-codemirror *ngIf="isCodeBlockVisible" [(ngModel)]="code" [options]="{
                          lineNumbers: true,
                          theme: 'default',
                          mode: 'markdown',
                          readOnly : true
                        }"></ngx-codemirror>
                    </div>
                </div>
            </div>
        </div>
        <div class="sequence-logs-wrapper" *ngIf="isSequenceTabSelected">
            <app-site-sequencer-table [isLoading]="isLoading" [table]="sequenceLogstableData" [hideTableHeader]="true"
                [headerColumns]="sequenceLogsHeaderColumns" [searchedSequenceLogsValue]="searchedSequenceLogsValue">
            </app-site-sequencer-table>
        </div>
        <div class="sequence-tabs-container">
            <div class="tabs-action-button-container"
                *ngIf="builderMode === 'sequence_builder' && ((canvasTabs.tabs.length || sequenceTabs.length) || isSearchActive)">
                <button *ngIf="!isSearchActive" mat-icon-button class="tab-action-button search-icon"
                    (click)="searchbtnClicked()">
                    <i class="fa fa-search icon"></i>
                </button>
                <mat-form-field *ngIf="isSearchActive" class="canvas-search-input">
                    <input class="canvas" [(ngModel)]="searchText" (ngModelChange)="searchSequence(searchText)" (blur)="closeSearch()" type="text"
                        matInput placeholder="Search for canvas" autofocus>
                    <i class="fa fa-search input-search-icon" matPrefix aria-hidden="true"></i>
                    <i (click)="clearCanvasSearch()" class="fa fa-times clear-search-icon" matSuffix aria-hidden="true"></i>
                </mat-form-field>
                <span class="seperator-line">|</span>
                <button [disabled]="isCreateCanvasLoading" mat-icon-button class="tab-action-button create-tab"
                    (click)="createNewCanvasTab()">
                    <i class="fa fa-plus icon"></i>
                </button>
            </div>
            <div class="no-canvas-text" *ngIf="builderMode === 'sequence_builder' && !canvasTabs.tabs.length && !sequenceTabs.length && !isLoading && siteId.length">
                No workspace found
            </div>

            <!-- Default Tab with sequence function name in the Library builder -->
            <div *ngIf="builderMode === 'library_builder'" class="tabs-container">
                <div class="tab active">
                    <div class="tab-inner-container">
                        <span class="tab-label">
                            {{defaultTabLabel}}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Canvas and Sequence Tabs in the Sequence builder -->
            <div *ngIf="builderMode === 'sequence_builder'" class="tabs-container">
                <div class="tab" *ngFor="let canvasTab of canvasTabs.tabs;let index = index;"
                    [ngClass]="{'active': (canvasTab.id == canvasTabs.selectedCanvasTab?.id) && !isSequenceTabSelected }"
                    (dblclick)="canvasDblClicked(canvasTab)" (contextmenu)="openMenu($event, canvasTab)"
                    (click)="canvasClicked(canvasTab)">
                    <div class="tab-inner-container">
                        <div class="tab-label" *ngIf="!canvasTab?.isCanvasNameEdit" 
                            matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                            {{canvasTab.name}}
                        </div>
                        <div *ngIf="canvasTab.isCanvasNameEdit">
                            <input #renameInput class="rename-input" [(ngModel)]="canvasTab.tempName" (blur)="renameCanvasText(canvasTab)"  maxlength="40" type="text" matInput
                            (click)="$event.stopPropagation()" placeholder="Search for canvas" autofocus>
                        </div>
                    </div>
                
                    <div *ngIf="canvasTab.showMenu" class="menu-container" [style.left.px]="contextMenuPostion.x">
                        <div (click)="showRenameCanvasInput(canvasTab, $event)" class="menu-option-text">
                            Rename
                        </div>
                        <div (click)="deleteCanvas(canvasTab, $event)" class="menu-option-text">
                            Delete
                        </div>
                    </div>
                </div>
                <div class="tab" *ngFor="let sequenceTab of sequenceTabs"
                    [ngClass]="{'active': (sequenceTab.seqId == selectedSequenceTab?.seqId) && isSequenceTabSelected }"
                    (click)="getSelectedSequenceTab(sequenceTab)">
                    <div class="tab-inner-container">
                        <div class="tab-label" matTooltip="" matTooltipPosition='above' showTooltipOnOverflow>
                            {{sequenceTab.seqName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loader *ngIf="isLoading"></app-loader>
</div>
