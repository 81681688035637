import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlockLogo, DeliveryLogsImage } from 'src/app/shared/enums/SequenceKeys.enum';
import { BlockColors } from '../blockly-theme';
const ALIGN_RIGHT=1;
const ALIGN_CENTRE=0;

export const BLOCKS = [
  {
    "type": "marker",
    "message0": "%1 Sequence %2 %3 run %4 %5 %6",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "markername"
      },
      {
        "type": "field_dropdown",
        "name": "RUN_STATUS",
        "options": [
          [
            "disable",
            "DISABLE"
          ],
          [
            "enable",
            "ENABLE"
          ],
          [
            "disable with logs",
            "DISABLE_WITH_LOGS"
          ],
          [
            "enable with logs",
            "ENABLE_WITH_LOGS"
          ],
          [
            "run only once",
            "DRY_RUN"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "Level",
        "options": [
          [
            "cloud only",
            "Cloud Only"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "Occurance",
        "options": [
          [
            "every minute",
            "EVERY_MINUTE"
          ],
          [
            "every hour",
            "HOURLY"
          ],
          [
            "once a day",
            "DAILY"
          ],
          [
            "once a week",
            "WEEKLY"
          ],
          [
            "once a month",
            "EVERY_MONTH"
          ],
        ]
      },
      {
        "type": "field_input",
        "name": "sequenceId"
      }
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "This block does the job of naming a sequence and contributes to the canvas navigation ",
    "helpUrl": ""
  },
  {
    "type": "sequence_system",
    "message0": "%1 Sequence %2 %3 run %4 scope %5 %6 %7 %8",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "markername"
      },
      {
        "type": "field_dropdown",
        "name": "RUN_STATUS",
        "options": [
          [
            "disable",
            "DISABLE"
          ],
          [
            "enable",
            "ENABLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "Occurance",
        "options": [
          [
            "every minute",
            "EVERY_MINUTE"
          ],
          [
            "every hour",
            "HOURLY"
          ],
          [
            "once a day",
            "DAILY"
          ],
          [
            "once a week",
            "WEEKLY"
          ],
          [
            "once a month",
            "EVERY_MONTH"
          ],
        ]
      },
      {
        "type": "field_dropdown",
        "name": "scope",
        "options": [
          [
            "select scope",
            "DEFAULT_SCOPE"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "sequenceId"
      },
      {
        "type": "field_input",
        "name": "selectedScopeValue"
      },
      {
        "type": "field_image",
        "src": DeliveryLogsImage.IMAGE,
        "width": DeliveryLogsImage.WIDTH,
        "height": DeliveryLogsImage.HEIGHT,
        "alt": DeliveryLogsImage.ALT_TEXT,
        "name": "deliveryLogs"
      },
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "This block does the job of naming a sequence and contributes to the canvas navigation ",
    "helpUrl": ""
  },
  {
    "type": "sequencer",
    "message0": "%1 sequencer %2 \n why %3 \n what %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "seqFunctionName"
      },
      {
        "type": "field_input",
        "name": "why"
      },
      {
        "type": "field_input",
        "name": "what"
      },
      {
        "type": "field_input",
        "name": "functionId"
      },
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "This block does the job of naming a sequence and contributes to the canvas navigation ",
    "helpUrl": ""
  },
  {
    "type": "haystack_query",
    "message0": "%1 query %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": 270,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_with_type",
    "message0": "%1 query %2 %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "QUERY_TYPE",
        "options": [
          [
            "point list",
            "list"
          ],
          [
            "point",
            "point"
          ],
          [
            "point with current value",
            "pointValue"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_with_condition",
    "message0": "%1 query %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "QUERY_TYPE",
        "options": [
          [
            "point list",
            "list"
          ],
          [
            "point",
            "point"
          ],
          [
            "point with current value",
            "pointValue"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "QUERY"
      },

      {
        "type": "input_dummy"
      },

      {
        "type": "input_value",
        "name": "Query_condition"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query text input",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_query_condition",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },

      {
        "type": "input_value",
        "name": "Query_LHS"
      },
      {
        "type": "field_dropdown",
        "name": "OPERATOR",
        "options": [
          [
            "==",
            "=="
          ],
          [
            "!=",
            "!="
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "Query_RHS"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_logical_operator",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "Query_LHS"
      },
      {
        "type": "field_dropdown",
        "name": "OPERATOR",
        "options": [
          [
            "and",
            "and"
          ],
          [
            "or",
            "or"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "Query_RHS"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "haystack_not_operator",
    "message0": "%1 not %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "condition"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Query conditions to filter",
    "helpUrl": "Documenation URL",
  },
  {
    "type": "getWrtiablePointValue",
    "message0": "%1 get current value at %2 for %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      }, {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "getCurrentHisValue",
    "message0": "%1 get current value for ID %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "pointwrite",
    "message0": "%1 write value %3 at %2 for %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "value"
      }, {
        "type": "input_value",
        "name": "id"
      }
    ],
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "inputsInline": true,
    "nextStatement": null,
    "previousStatement": null,
    "tooltip": "Write given value to specific point id",
    "helpUrl": "Documenataion links for haystack tags"
  },
  {
    "type": "point_write_null",
    "message0": "%1 clear value at %2 for %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "level",
        "value": "10",
        "options": [
          [
            "level 1",
            "1"
          ],
          [
            "level 2",
            "2"
          ],
          [
            "level 3",
            "3"
          ], [
            "level 4",
            "4"
          ], [
            "level 5",
            "5"
          ], [
            "level 6",
            "6"
          ], [
            "level 7",
            "7"
          ], [
            "level 8",
            "8"
          ], [
            "level 9",
            "9"
          ],
          [
            "level 10",
            "10"
          ], [
            "level 11",
            "11"
          ], [
            "level 12",
            "12"
          ],
          [
            "level 13",
            "13"
          ], [
            "level 14",
            "14"
          ],
          [
            "level 15",
            "15"
          ], [
            "level 16",
            "16"
          ], [
            "level 17",
            "17"
          ]
        ]
      }, {
        "type": "input_value",
        "name": "id"
      }
    ],
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "inputsInline": true,
    "nextStatement": null,
    "previousStatement": null,
    "tooltip": "Write given value to specific point id",
    "helpUrl": "Documenataion links for haystack tags"
  },
  {
    "type": "hisWrite",
    "message0": "%1 write historized value %2 for %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "value"
      }, {
        "type": "input_value",
        "name": "id"
      }
    ],
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "inputsInline": true,
    "nextStatement": null,
    "previousStatement": null,
    "tooltip": "Histrozied Write given value to specific point id",
    "helpUrl": "Documenataion links for haystack tags"
  },
  {
    "type": "getHistorizedValue",
    "message0": "%1 get historized value from %2 for last %3 mins %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_value",
        "name": "INTERVAL"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "getHistorizedValueExcludeMins",
    "message0": "%1 get historized value from %2 for last %3 mins excluding recent %4 mins %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "input_value",
        "name": "INTERVAL"
      },
      {
        "type": "input_value",
        "name": "EXCLUDE_INTERVAL"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id",
    "helpUrl": "Fetch current value for point id"
  },
  {
    "type": "getHistorizedValueFromDateRange",
    "message0": "%1 get historized value from %2 from %3 to %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValues",
    "message0": "%1 get historized value from %2 \n groupBy %3 %4 aggregateBy %5 interpolate %6 for last %7 %8 %9",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      }, {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_number",
        "name": "rangeValue",
        "value": "1"
      },
      {
        "type": "field_dropdown",
        "name": "rangeUnit",
        "value": "day",
        "options": [
          [
            "day(s)",
            "day"
          ],
          [
            "hour(s)",
            "hour"
          ],
          [
            "minutes",
            "min"
          ]
        ]
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValuesFromDateRange",
    "message0": "%1 get historized value from %2 groupBy %3 %4 aggregateBy %5 interpolate %6 from %7 to %8 %9",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      }, {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getAggregatedValuesFromDateTimeVariant",
    "message0": "%1 get historized value from %2 groupBy %3 %4 aggregateBy %5 interpolate %6 from %7 %8 to %9 %10 %11",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "groupByValue",
        "value": "5"
      },
      {
        "type": "field_dropdown",
        "name": "groupBy",
        "value": "min",
        "options": [
          [
            "minute",
            "min"
          ],
          [
            "hour",
            "hr"
          ],
          [
            "day",
            "day"
          ], [
            "month",
            "mo"
          ], [
            "year",
            "year"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "aggregationFunction",
        "value": "avg",
        "options": [
          [
            "average",
            "avg"
          ],
          [
            "count",
            "count"
          ],
          [
            "min",
            "min"
          ],
          [
            "max",
            "max"
          ],
          [
            "sum",
            "sum"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "interpolationMethod",
        "value": "linear",
        "options": [
          [
            "linear",
            "linear"
          ],
          [
            "cov",
            "cov"
          ],
          [
            "none",
            "none"
          ],
          [
            "null",
            "null"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "start_time",
        "text": "00:00"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_time",
        "text": "23:59"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "gethistorizedNVariant",
    "message0": "%1 get historized value from %2 for last %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_number",
        "name": "rangeValue",
        "value": "1"
      },
      {
        "type": "field_dropdown",
        "name": "rangeUnit",
        "value": "day",
        "options": [
          [
            "day(s)",
            "day"
          ],
          [
            "hour(s)",
            "hour"
          ],
          [
            "minutes",
            "min"
          ]
        ]
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "gethistorizedDateTimeVariant",
    "message0": "%1 get historized value for %2 from %3 %4 to %5 %6",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "PointID"
      },
      {
        "type": "field_input",
        "name": "start_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "start_time",
        "text": "00:00"
      },
      {
        "type": "field_input",
        "name": "end_date",
        "text": "YYYY-MM-DD"
      },
      {
        "type": "field_input",
        "name": "end_time",
        "text": "23:59"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Fetch current value for point id from date range",
    "helpUrl": "Fetch current value for point id from date range"
  },
  {
    "type": "getKeyValue",
    "message0": "%1 get value for key %3 from %2 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "item"
      },
      {
        "type": "field_input",
        "name": "key",
        "text": "key name"
      }, {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Access the specific property/ key of an object",
    "helpUrl": ""
  },
  {
    "type": "alert",
    "message0": "%1 alert title %2 \n message %3 \n notification %4 \n  default State %5 %6 \n type %7 %8 \n severity %9 %10",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "field_input",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "field_input",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_STATE",
        "options": [
          [
            "enabled",
            "Enabled"
          ],
          [
            "disabled",
            "Disabled"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      },
      {
        "type": "input_dummy"
      }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts"
  },
  {
    "type": "logger",
    "message0": "%1 log name %2 value %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "log_name"
      },
      {
        "type": "input_value",
        "name": "log_value"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Add a custom comment to your code."
  },
  {
    "type": "comment",
    "message0": "%1 comment %2",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "comment"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Add a custom comment to your code."
  },
  {
    "kind": "block",
    "type": "maparray",
    "inputs": {
      "list": {
        "block": {
          "type": "variables_get",
          "fields": {
            "VAR": "{listVariable}"
          }
        }
      }
    },
    "previousStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Add a custom comment to your code."
  },
  {
    "type": "alertWithRef",
    "message0": "%1 alert title %2 reference %3 \n message %4 \n notification %5 \n type %6 \n severity %7",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "input_value",
        "name": "ALERT_REFERENCE"
      },
      {
        "type": "input_value",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "input_value",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "Moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      }
    ],
    "inputsInline": true,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.ALERTS_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts"
  },
  {
    "type": "create_persistent_variable",
    "message0": "%1 persist %2 default %3 %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_value",
        "name": "VARIABLE_VALUE"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Create persistent variable",
  },
  {
    "type": "set_persistent_variable",
    "message0": "%1 persist set %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_value",
        "name": "VARIABLE_VALUE"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Set persistent variable value",
  },
  {
    "type": "get_persistent_variable",
    "message0": "%1 persist get %2 %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "VARIABLE_NAME"
      },
      {
        "type": "input_dummy"
      }
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Set persistent variable value",
  },
  {
    "type": "alertWithOffset",
    "message0": "%1 alert title %2 reference %3 \n message %4 \n notification %5 \n type %6 \n severity %7 \n hold down runs %8 \n fix hold down runs %9",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "input_value",
        "name": "ALERT_REFERENCE"
      },
      {
        "type": "input_value",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "input_value",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "Moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "ALERT_TRIGGER_OFFSET",
        "value": "5"
      },
      {
        "type": "field_input",
        "name": "ALERT_FIX_OFFSET",
      }
    ],
    "inputsInline": true,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.ALERTS_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts",
  },
  {
    "type": "alertHystersis",
    "message0": "%1 alert title %2 reference %3 \n message %4 \n notification %5 \n type %6 \n severity %7 \n value %8 \n Enable Upper Limt %9 \n Upper Limit %10 hold down runs  %11 Upper Limit fix %12 %13 %14 \n Enable Lower Limt %15 \n Lower Limit %16 hold down runs %17 Lower Limit fix %18 %19 %20",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "ALERT_TITLE"
      },
      {
        "type": "input_value",
        "name": "ALERT_REFERENCE"
      },
      {
        "type": "input_value",
        "name": "ALERT_MESSAGE"
      },
      {
        "type": "input_value",
        "name": "ALERT_NOTIFICATION_MESSAGE"
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_TYPE",
        "options": [
          [
            "customer visible",
            "CUSTOMER VISIBLE"
          ],
          [
            "customer invisible",
            "CUSTOMER INVISIBLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "ALERT_SEVERITY",
        "options": [
          [
            "severe",
            "SEVERE"
          ],
          [
            "Moderate",
            "MODERATE"
          ],
          [
            "low",
            "LOW"
          ],
          [
            "internal info",
            "INTERNAL_INFO"
          ]
        ]
      },
      
      {
        "type": "input_value",
        "name": "VALUE",
        "check": "Number"
      },
      {
        "type": "field_dropdown",
        "name": "SHOW_UPPER_LIMIT",
        "options": [
          [
            "Yes",
            "YES"
          ],
          [
            "No",
            "NO"
          ]
        ],
        "default": "YES"
      },
      {
        "type": "input_value",
        "name": "ALERT_TRIGGER_UPPER_LIMIT",
        "check": "Number"
      },
      {
        "type": "field_input",
        "name": "ALERT_TRIGGER_UPPER_LIMIT_OFFSET",
      },
      {
        "type": "input_value",
        "name": "ALERT_FIX_UPPER_LIMIT",
        "check": "Number"
      },
      {
        "type": "field_label",
        "name": "ALERT_FIX_UPPER_LIMIT_OFFSET_LABEL",
        "text": "fix hold down runs"
      },
      {
        "type": "field_input",
        "name": "ALERT_FIX_UPPER_LIMIT_OFFSET",
      },
      {
        "type": "field_dropdown",
        "name": "SHOW_LOWER_LIMIT",
        "options": [
          [
            "Yes",
            "YES"
          ],
          [
            "No",
            "NO"
          ]
        ],
        "default": "YES"
      },
      {
        "type": "input_value",
        "name": "ALERT_TRIGGER_LOWER_LIMIT",
        "check": "Number"
      },
      {
        "type": "field_input",
        "name": "ALERT_TRIGGER_LOWER_LIMIT_OFFSET",
      },
      {
        "type": "input_value",
        "name": "ALERT_FIX_LOWER_LIMIT",
        "check": "Number"
      },
      {
        "type": "field_label",
        "name": "ALERT_FIX_LOWER_LIMIT_OFFSET_LABEL",
        "text": "fix hold down runs"
      },
      {
        "type": "field_input",
        "name": "ALERT_FIX_LOWER_LIMIT_OFFSET",
      },
     
      
    ],
    "inputsInline": true,
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.ALERTS_BLOCKS,
    "tooltip": "Create Custom Alert Details",
    "helpUrl": "Documentation Links for Custom Alerts",
    "mutator": "hystersis_mutator",
  },
  {
    "type": "compare_date_inbetween",
    "message0": "%1 current date time in range with start date time %2 end date time %3 format %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "START_DATE_TIME"
      },
      {
        "type": "input_value",
        "name": "END_DATE_TIME"
      },
      {
        "type": "field_dropdown",
        "name": "DATE_TIME_FORMAT",
        "options": [
          ["yyyy-MM-ddTHH:mm:ssZ", "dateTimeFormatEnum.DATE_TIME_UTC"],
          ["yyyy-MM-dd HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_1"],
          ["yyyy/MM/dd HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_1"],
          ["dd-MM-yyyy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_2"],
          ["dd/MM/yyyy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_2"],
          ["MM-dd-yyyy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_3"],
          ["MM/dd/yyyy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_3"],
          ["yy-MM-dd HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_4"],
          ["yy/MM/dd HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_4"],
          ["dd-MM-yy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_5"],
          ["dd/MM/yy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_5"],
          ["MM-dd-yy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_HYPHEN_6"],
          ["MM/dd/yy HH:mm[:ss]","dateTimeFormatEnum.DATE_TIME_IN_SLASH_6"],
          ["yyyy-MM-dd","dateFormatEnum.DATE_IN_HYPHEN_1"],
          ["yyyy/MM/dd","dateFormatEnum.DATE_IN_SLASH_1"],
          ["dd-MM-yyyy","dateFormatEnum.DATE_IN_HYPHEN_2"],
          ["dd/MM/yyyy","dateFormatEnum.DATE_IN_SLASH_2"],
          ["MM-dd-yyyy","dateFormatEnum.DATE_IN_HYPHEN_3"],
          ["MM/dd/yyyy","dateFormatEnum.DATE_IN_SLASH_3"],
          ["yy-MM-dd","dateFormatEnum.DATE_IN_HYPHEN_4"],
          ["yy/MM/dd","dateFormatEnum.DATE_IN_SLASH_4"],
          ["dd-MM-yy","dateFormatEnum.DATE_IN_HYPHEN_5"],
          ["dd/MM/yy","dateFormatEnum.DATE_IN_SLASH_5"],
          ["MM-dd-yy","dateFormatEnum.DATE_IN_HYPHEN_6"],
          ["MM/dd/yy","dateFormatEnum.DATE_IN_SLASH_6"],
          ["hh:mm:ss a","timeFormatEnum.TIME_IN_12_HR_1"],
          ["hh:mm a","timeFormatEnum.TIME_IN_12_HR_2"],
          ["HH:mm:ss","timeFormatEnum.TIME_IN_24_HR_1"],
          ["HH:mm","timeFormatEnum.TIME_IN_24_HR_2"]
        ]
      },
    ],
    "output": null,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Set persistent variable value",
  },
  {
    "type": "date_time_format",
    "message0": "%1Format %2 Timezone %3 Type %4",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FORMAT",
        "options": [
          ["yyyy-mm-ddTHH:MM:SSZ", "yyyy-mm-ddTHH:MM:SSZ"],
          ["yy-mm-dd", "yy-mm-dd"],
          ["hh:mm:ss a", "hh:mm:ss a"],
          ["HH:MM:SS", "HH:MM:SS"],
          ["dd-mm-yyyy", "dd-mm-yyyy"]
        ]
      },
      {
        "type": "input_value",
        "name": "TIMEZONE"
      },
      {
        "type": "field_dropdown",
        "name": "TYPE",
        "options": [
          ["Current Time", "CURRENT"],
          ["Start of Day", "START"],
          ["End of Day", "END"]
        ]
      }
    ],
    "output": "String",
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Returns date/time in specified format and timezone.",
    "helpUrl": ""
  },
  {
    "type": "xml_to_json",
    "message0": "%1 Convert XML to JSON %2 ",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "XML",
        "check": "String"
      }
    ],
    "output": "String",
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Converts an XML string to JSON format",
    "helpUrl": ""
  },
  {
    "type": "date_format_converter",
    "message0": "%1 Convert date %2 to format %3",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      { "type": "input_value", "name": "DATE", "check": "String" },
      {
        "type": "field_dropdown",
        "name": "FORMAT",
        "options": [
          ["yyyy-MM-dd HH:mm:ss", "YYYY-MM-DD HH:mm:ss"],
          ["MM/dd/yyyy", "MM/DD/YYYY"],
          ["dd-MM-yyyy HH:mm", "DD-MM-YYYY HH:mm"],
          ["yyyy/MM/dd", "YYYY/MM/DD"],
          ["yyyy-MM-ddTHH:mm:ssZ", "ISO"]
        ]
      }
    ],
    "output": "String",
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "Convert a date string to the specified format.",
    "helpUrl": ""
  },
  {
    "type": "mqtt_subscribe",
    "message0": "%1 Sequence %2 %3 \n MQTT Subscription \n url %4 \n username %5 \n password %6 \n topic %7 %8",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "markername"
      },
      {
        "type": "field_dropdown",
        "name": "RUN_STATUS",
        "options": [
          [
            "disable",
            "DISABLE"
          ],
          [
            "enable",
            "ENABLE"
          ],
          [
            "disable with logs",
            "DISABLE_WITH_LOGS"
          ],
          [
            "enable with logs",
            "ENABLE_WITH_LOGS"
          ],
          [
            "run only once",
            "DRY_RUN"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "brokerUrl"
      },
      {
        "type": "input_value",
        "name": "username"
      },
      {
        "type": "input_value",
        "name": "password"
      },
      {
        "type": "input_value",
        "name": "topic"
      },
      {
        "type": "field_input",
        "name": "sequenceId"
      }
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.MQTT_BLOCKS,
    "tooltip": "",
    "helpUrl": ""
  },
  {
    "type": "mqtt_get",
    "message0": "%1 Get mqtt event message",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
    ],
    "output": null,
    "inputsInline": true,
    "colour": BlockColors.MQTT_BLOCKS,
    "tooltip": "",
    "helpUrl": ""
  },
  {
    "type": "mqtt_get_topic_data",
    "message0": "%1 Get mqtt event with topic",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
    ],
    "output": null,
    "inputsInline": true,
    "colour": BlockColors.MQTT_BLOCKS,
    "tooltip": "",
    "helpUrl": ""
  },
  {
    "type": "mqtt_publish",
    "message0": "%1 MQTT Publish \n url %2 \n username %3 \n password %4 \n topic %5 \n message %6 %7",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "input_value",
        "name": "brokerUrl"
      },
      {
        "type": "input_value",
        "name": "username"
      },
      {
        "type": "input_value",
        "name": "password"
      },
      {
        "type": "input_value",
        "name": "topic"
      },
      {
        "type": "input_value",
        "name": "message"
      },
      {
        "type": "input_dummy"
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": BlockColors.MQTT_BLOCKS,
    "tooltip": "",
    "helpUrl": ""
  }
];

export class dynamicBlockBuilder {
  static functionNameValidator(name: string) {
    return name.replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_]/g, '_');
  }
  static sequencerFunctionblockInit(block: any) {
    Blockly.Blocks[block.type] = {
      init: function () {
        this.appendDummyInput()
          .appendField(this.getBlockImage(), 'blockImage')
          .appendField(new Blockly.FieldLabel(block.seqFunctionName), 'seqFunctionName')
          .appendField(new Blockly.FieldLabel(block.functionId), 'functionId')
          .appendField(new Blockly.FieldTextInput(block.xml), 'xml')
          .appendField(new Blockly.FieldTextInput(block.snippet), 'snippet')
          .appendField(new Blockly.FieldTextInput(block.why), 'why')
          .appendField(new Blockly.FieldTextInput(block.what), 'what')
        this.setColour(270);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip(`Why: ${block.why}, What: ${block.what}`);
        this.setHelpUrl('');
        this.updateBlockShape();
      },

      // Create an image field with specified source, width, and height
      getBlockImage() {
        const src = BlockLogo.IMAGE;
        const width = BlockLogo.WIDTH;
        const height = BlockLogo.HEIGHT;
        const alt = BlockLogo.ALT_TEXT
        return new Blockly.FieldImage(src, width, height, alt);
      },

      // Hiding the xml and snippet fields of the block.
      updateBlockShape: function () {
        this.getField('functionId').setVisible(false);
        this.getField('xml').setVisible(false);
        this.getField('snippet').setVisible(false);
        this.getField('why').setVisible(false);
        this.getField('what').setVisible(false);
      }
    }

    // Code generation
    javascriptGenerator.forBlock[block.type] = function (block: any) {
      var functionName = block.getFieldValue('seqFunctionName');
      var code = block.getFieldValue('snippet');
      code = `\n${dynamicBlockBuilder.functionNameValidator(functionName)}()\n`;
      return code;
    };
  };

  static createObjectBlockInit() {
    const objectCreateBlockDef = {
      "type": "object_create",
      "message0": '%1 create object',
      args0: [
        {
          "type": "field_image",
          "src": BlockLogo.IMAGE,
          "width": BlockLogo.WIDTH,
          "height": BlockLogo.HEIGHT,
          "alt": BlockLogo.ALT_TEXT
        },
      ],
      "output": "Object",
      "mutator": "controls_create_mutator",
      "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
      "tooltip": "Create a new object, optionally with some property values.",
      "helpUrl": ""
    };
    
    Blockly.Blocks['object_create'] = {
      init: function () {
        this.jsonInit(objectCreateBlockDef);
      }
    };

    const objectFieldBlockDef = {
      "type": "object_field",
      "message0": "%1 %2",
      "args0": [
        {
          "type": "field_input",
          "name": "field_name",
          "text": "property name"
        },
        {
          "type": "input_value",
          "name": "field_value"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
      "tooltip": "",
      "helpUrl": ""
    };
    
    Blockly.Blocks['object_field'] = {
      init: function () {
        this.jsonInit(objectFieldBlockDef);
      }
    };

    const objectCreateMutatorBlockDef = {
      "type": "object_create_mutator",
      "message0": 'create object',
      "nextStatement": null,
      "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
      "tooltip": 'Create a new object, optionally with some property values.',
      "helpUrl": ""
    };
    
    Blockly.Blocks['object_create_mutator_top'] = {
      init: function () {
        this.jsonInit(objectCreateMutatorBlockDef);
      }
    };

    const objectCreateMutator = {
      numFields: 0,
      fields: new Array<any>(),
    
      /**
       * Standard function for Mutator mixin. It's called to update the block based on contents of the mutator's XML
       * DOM element.
       */
      domToMutation: function(xmlElement:any) {
        this.fields = [];
        for (let i = 0, childNode; childNode = xmlElement.childNodes[i]; i++) {
          if (childNode.nodeName.toLowerCase() == 'field') {
            this.fields.push(childNode.getAttribute('name'));
          }
        }
        this.numFields = this.fields.length;
        this.updateShape();
      },
    
      /**
       * Standard function for Mutator mixin. It's called to generate the mutator's XML DOM element based on the content
       * of the block.
       */
      mutationToDom: function() {
        if (!this.numFields) {
          return null;
        }
        const container = document.createElement('mutation');
        container.setAttribute('num_fields', '' + this.numFields);
        for (let i = 0; i < this.fields.length; i++) {
          const field = document.createElement('field');
          field.setAttribute('name', this.fields[i]);
          container.appendChild(field);
        }
        return container;
        
      },
    
      /**
       * Standard function for Mutator mixin when the mutator uses the standard mutator UI. It's called to update the
       * block based on changes to the mutator's UI.
       */
      compose: function(topBlock: any) {
        let fieldBlock = topBlock.nextConnection && topBlock.nextConnection.targetBlock();
        this.numFields = 0;
        this.fields = [];
        let connectionsToRestore = [null];
        while (fieldBlock) {
          this.fields.push(fieldBlock.getFieldValue('field_name'));
          this.numFields++;
          connectionsToRestore.push(fieldBlock.savedConnection);
          fieldBlock = fieldBlock.nextConnection && fieldBlock.nextConnection.targetBlock();
        }
        this.updateShape();
        // Reconnect any child blocks.
        for (let i = 0; i < connectionsToRestore.length; i++) {
          let input = (this as any).getInput('field_input' + (i));
          if (input && input.connection && connectionsToRestore[i]) {
            input.connection.connect(connectionsToRestore[i]);
          }
        }
      },
    
      /**
       * Standard function for Mutator mixin when the mutator uses the standard mutator UI.  It's called to populate the
       * mutator UI.
       */
      decompose: function(workspace:any) {
        const topBlock = workspace.newBlock('object_create_mutator_top');
        topBlock.initSvg();
        let connection = topBlock.nextConnection;
        for (let i = 0; i < this.fields.length; i++) {
          const fieldBlock = workspace.newBlock('object_field');
          fieldBlock.initSvg();
          fieldBlock.setFieldValue(this.fields[i], 'field_name');
          connection.connect(fieldBlock.previousConnection);
          connection = fieldBlock.nextConnection;
        }
        return topBlock;
      },
    
      /**
       * Standard function for Mutator mixin when the mutator uses the standard mutator UI.  It's called on any changes to
       * the block and is generally used to keep track of input connections (by saving them with their corresponding mutator
       * blocks), so that if the mutator later causes changes to the block it can restore those input connections.
       *
       * We're also using this function to update the mutator block field name values if the user changes the name in the
       * block.
       */
      saveConnections: function(topBlock:any) {
        let fieldBlock = topBlock.nextConnection && topBlock.nextConnection.targetBlock();
        let i = 1;
        while (fieldBlock) {
          const input = (this as any).getInput('field_input' + i);
          fieldBlock.savedConnection = input && input.connection.targetConnection;
          // Set mutator block field name from the corresponding 'real' Object.create block
          fieldBlock.setFieldValue((this as any).getFieldValue('field' + i), 'field_name');
          i++;
          fieldBlock = fieldBlock.nextConnection &&
            fieldBlock.nextConnection.targetBlock();
        }
      },
    
      updateShape: function() {
        // Delete everything.
        if ((this as any).getInput('with')) {
          (this as any).removeInput('with');
        }
        let i = 1;
        while ((this as any).getInput('field_input' + i)) {
          (this as any).removeInput('field_input' + i);
          i++;
        }
        // Rebuild block.
        if (this.numFields > 0) {
          (this as any).appendDummyInput('with')
          .setAlign(ALIGN_RIGHT)
          .appendField("with fields");
        }
        for (let i = 1; i <= this.numFields; i++) {
          const fieldName = this.fields[i - 1];
          (this as any).appendValueInput("field_input" + i)
            .setCheck(null)
            .setAlign(ALIGN_RIGHT)
            .appendField(new Blockly.FieldTextInput(fieldName), "field" + i);
        }
      },
    
    };
    try{
      Blockly.Extensions.registerMutator('controls_create_mutator', objectCreateMutator, undefined, ['object_field']);
    }
    catch(e){
      //already registered
    }
  }

  static httpRequestBlocksInit() {
    Blockly.Blocks["http_request_block"] = {
      init: function () {
        this.jsonInit({
          "type": "http_request_block",
          "message0": "%1 External HttpRequest \n Url %2 \n Request Header: %3 Request Type %4 \n",
          "args0": [
            {
              "type": "field_image",
              "src": BlockLogo.IMAGE,
              "width": BlockLogo.WIDTH,
              "height": BlockLogo.HEIGHT,
              "alt": BlockLogo.ALT_TEXT
            },
            {
              "type": "field_input",
              "name": "request_url",
              "text": "Enter external request url"
            },
            {
              "type": "input_value",
              "name": "request-header-input"
            },
            {
              "type": "field_dropdown",
              "name": "request_method",
              "options": [
                ["GET", "GET"],
                ["POST", "POST"],
                ["PUT", "PUT"],
                ["PATCH", "PATCH"],
                ["DELETE", "DELETE"]
              ],
              "onchange": this.onHttpMethodChange.bind(this)
            }
          ],
          "output": null,
          "inputsInline": false,
          "colour": BlockColors.API_BLOCKS,
          "tooltip": "Block for a Http Request",
          "helpUrl": "Documentation URL"
        });

        // Add event listener for dropdown change
        this.getField('request_method').setValidator(this.onHttpMethodChange.bind(this));
      },

      mutationToDom: function () {
        var container = Blockly.utils.xml.createElement('mutation');
        var httpMethod = this.getFieldValue('request_method');
        container.setAttribute('request_method', httpMethod);

        // Save block position
        var xy = this.getRelativeToSurfaceXY();
        container.setAttribute('x', xy.x);
        container.setAttribute('y', xy.y);

        return container;
      },

      domToMutation: function (xmlElement: any) {
        var httpMethod = xmlElement.getAttribute('request_method');
        this.updateShape_(httpMethod);

        // Restore block position
        var x = parseInt(xmlElement.getAttribute('x'), 10);
        var y = parseInt(xmlElement.getAttribute('y'), 10);
        if (!isNaN(x) && !isNaN(y)) {
          this.moveBy(x, y);
        }
      },

      onHttpMethodChange: function (newValue: any) {
        this.updateShape_(newValue);
      },

      updateShape_: function (httpMethod: any) {
        // Remove existing 'postData' input if it exists
        if (this.getInput('postData')) {
          this.removeInput('postData');
        }

        // Add 'postData' input only if the selected method is POST
        if (httpMethod === 'PATCH' || httpMethod === 'PUT' || httpMethod === 'POST') {
          this.appendValueInput('postData')
            .setCheck(null)  // Allow any type of block to be connected
            .appendField('Request Body');
        }

        // Return null to indicate no issues with the new value
        return null;
      }
    };

    Blockly.Blocks["http_request_block_with_input"] = {
      init: function () {
        this.jsonInit({
          "type": "http_request_block",
          "message0": "%1 External HttpRequest \n Url %2 \n Request Header: %3 Request Type %4 \n",
          "args0": [
            {
              "type": "field_image",
              "src": BlockLogo.IMAGE,
              "width": BlockLogo.WIDTH,
              "height": BlockLogo.HEIGHT,
              "alt": BlockLogo.ALT_TEXT
            },
            {
              "type": "input_value",
              "name": "request_url",
              "text": "Enter external request url"
            },
            {
              "type": "input_value",
              "name": "request-header-input"
            },
            {
              "type": "field_dropdown",
              "name": "request_method",
              "options": [
                ["GET", "GET"],
                ["POST", "POST"],
                ["PUT", "PUT"],
                ["PATCH", "PATCH"],
                ["DELETE", "DELETE"]
              ],
              "onchange": this.onHttpMethodChange.bind(this)
            }
          ],
          "output": null,
          "inputsInline": false,
          "colour": BlockColors.API_BLOCKS,
          "tooltip": "Block for a Http Request",
          "helpUrl": "Documentation URL"
        });

        // Add event listener for dropdown change
        this.getField('request_method').setValidator(this.onHttpMethodChange.bind(this));
      },

      mutationToDom: function () {
        var container = Blockly.utils.xml.createElement('mutation');
        var httpMethod = this.getFieldValue('request_method');
        container.setAttribute('request_method', httpMethod);

        // Save block position
        var xy = this.getRelativeToSurfaceXY();
        container.setAttribute('x', xy.x);
        container.setAttribute('y', xy.y);

        return container;
      },

      domToMutation: function (xmlElement: any) {
        var httpMethod = xmlElement.getAttribute('request_method');
        this.updateShape_(httpMethod);

        // Restore block position
        var x = parseInt(xmlElement.getAttribute('x'), 10);
        var y = parseInt(xmlElement.getAttribute('y'), 10);
        if (!isNaN(x) && !isNaN(y)) {
          this.moveBy(x, y);
        }
      },

      onHttpMethodChange: function (newValue: any) {
        this.updateShape_(newValue);
      },

      updateShape_: function (httpMethod: any) {
        // Remove existing 'postData' input if it exists
        if (this.getInput('postData')) {
          this.removeInput('postData');
        }

        // Add 'postData' input only if the selected method is POST
        if (httpMethod === 'PATCH' || httpMethod === 'PUT' || httpMethod === 'POST') {
          this.appendValueInput('postData')
            .setCheck(null)  // Allow any type of block to be connected
            .appendField('Request Body');
        }

        // Return null to indicate no issues with the new value
        return null;
      }
    };
    this.createObjectBlockInit();
  }

  static httpHeaderBlocksInit() {
    const minusImage =
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAw' +
      'MC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPS' +
      'JNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMmgxMmMxLjEwNCAw' +
      'IDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K';

    const plusImage =
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC' +
      '9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPSJNMT' +
      'ggMTBoLTR2LTRjMC0xLjEwNC0uODk2LTItMi0ycy0yIC44OTYtMiAybC4wNzEgNGgtNC4wNz' +
      'FjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAybDQuMDcxLS4wNzEtLjA3MSA0LjA3MW' +
      'MwIDEuMTA0Ljg5NiAyIDIgMnMyLS44OTYgMi0ydi00LjA3MWw0IC4wNzFjMS4xMDQgMCAyLS' +
      '44OTYgMi0ycy0uODk2LTItMi0yeiIgZmlsbD0id2hpdGUiIC8+PC9zdmc+Cg==';

    Blockly.Blocks["http_request_header"] = {
      init: function () {
        this.itemCount_ = 0;
        this.jsonInit({
          "type": "http_request_header",
          "message0": "%1 HttpHeader \n Authorization Type %2",
          "args0": [
            {
              "type": "field_image",
              "src": BlockLogo.IMAGE,
              "width": BlockLogo.WIDTH,
              "height": BlockLogo.HEIGHT,
              "alt": BlockLogo.ALT_TEXT
            },
            {
              "type": "field_dropdown",
              "name": "auth_type",
              "options": [
                ["NONE", "NONE"],
                ["BEARER", "BEARER"],
                ["API-KEY", "API-KEY"],
                ["BASIC-AUTH", "BASIC-AUTH"]
              ]
            }
          ],

          "output": null,
          "inputsInline": false,
          "colour": BlockColors.API_BLOCKS,
          "tooltip": "HTTP Request Header",
          "helpUrl": "Documentation URL",
        });

        let appendFieldPlusIcon = new Blockly.FieldImage(plusImage, 15, 15, "Add", this.appendArrayElementInput.bind(this));
        let appendFieldMinusIcon = new Blockly.FieldImage(minusImage, 15, 15, "Remove", this.deleteLastArrayElementInput.bind(this));

        this.appendDummyInput("buttons")
          .appendField(appendFieldPlusIcon)
          .appendField(appendFieldMinusIcon)
          .appendField("Add /Remove Parameter");

        // Attach a change listener to the auth_type dropdown
        this.getField('auth_type').setValidator(this.updateAuthTypeInput_.bind(this));
      },

      mutationToDom: function () {
        var container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('items', this.itemCount_);
        return container;
      },

      domToMutation: function (xmlElement: any) {
        this.itemCount_ = Number(xmlElement.getAttribute('items'));
        this.updateShape();
      },

      appendArrayElementInput: function () {
        this.itemCount_ += 1;
        this.appendSingleArrayElementInput(this.itemCount_ - 1);
      },

      deleteLastArrayElementInput: function () {
        if (this.itemCount_ > 0) {
          this.removeInput("element_" + (this.itemCount_ - 1), true);
          this.itemCount_ -= 1;
        }
      },

      appendSingleArrayElementInput: function (index: any) {
        const appendedInput = this.appendValueInput("element_" + index);
        appendedInput.appendField("Key  ").setAlign(Blockly.inputs.Align.LEFT);
        const lhsInput = new Blockly.FieldDropdown([
          ["Content-Type", "Content-Type"],
          ["Accept", "Accept"],
          ["Connection", "Connection"],
          ["Accept-Encoding", "Accept-Encoding"],
          ["Host", "Host"],
          ["User-Agent", "User-Agent"],
          ["ID", "ID"],
          ["Client_Id", "Client_Id"],
          ["Client_Secret", "Client_Secret"],
          ["75F-Application", "75F-Application"],
          ["Other-Key", "Other-Key"]
        ], this.handleDropdownChange_.bind(this, index));

        let customInput = new Blockly.FieldTextInput('', null);
        appendedInput.appendField(customInput, "CustomInput_" + index).setAlign(Blockly.inputs.Align.LEFT);
        this.getField("CustomInput_" + index).setVisible(false);
        appendedInput.appendField(lhsInput, 'headerParamKey_' + index).setAlign(Blockly.inputs.Align.LEFT);
        appendedInput.appendField("Value").setAlign(Blockly.inputs.Align.LEFT);
      },

      updateShape: function () {
        // Append existing elements if any
        for (let i = 0; i < this.itemCount_; i++) {
          if (!this.getInput("element_" + i)) {
            this.appendSingleArrayElementInput(i);
          }
        }
      },

      handleDropdownChange_: function (index: any, newValue: any) {
        if (newValue === 'Other-Key') {
          this.getField("CustomInput_" + index).setVisible(true);
          this.getField("headerParamKey_" + index).setVisible(false);
        } else {
          this.getField("CustomInput_" + index).setVisible(false);
        }
      },

      updateAuthTypeInput_: function (newValue: any) {
        // Remove any existing authorization inputs
        if (this.getInput('auth_input')) {
          this.removeInput('auth_input');
        }
        if (this.getInput('auth_user_input')) {
          this.removeInput('auth_user_input');
        }
        if (this.getInput('auth_pass_input')) {
          this.removeInput('auth_pass_input');
        }

        if (newValue !== 'NONE') {
          if (newValue === 'BASIC-AUTH') {
            // Add input fields for User Name and Password
            this.appendDummyInput('auth_user_input')
              .appendField('User Name')
              .appendField(new Blockly.FieldTextInput(''), 'auth_user_value');
            this.moveInputBefore('auth_user_input', 'buttons');

            this.appendDummyInput('auth_pass_input')
              .appendField('Password')
              .appendField(new Blockly.FieldTextInput(''), 'auth_pass_value');
            this.moveInputBefore('auth_pass_input', 'buttons');
          } else {
            // Add the new input for the auth value
            const appendedAuthValue = this.appendValueInput("auth_input");
            appendedAuthValue.appendField("Authorization Key  ");
            this.moveInputBefore('auth_input', 'buttons');
          }
        }
      }
    };
  }

  static httpRequestBodyBlocksInit() {
    Blockly.Blocks["http_request_body"] = {
      init: function () {
        this.jsonInit({
          "type": "http_request_body",
          "message0": "%1 Data : \n",
          "args0": [
            {
              "type": "field_image",
              "src": BlockLogo.IMAGE,
              "width": BlockLogo.WIDTH,
              "height": BlockLogo.HEIGHT,
              "alt": BlockLogo.ALT_TEXT
            }
          ],
          "output": null,
          "inputsInline": true,
          "colour": BlockColors.API_BLOCKS,
          "tooltip": "HTTP Request Body",
          "helpUrl": "Documentation URL"
        });

        let appendMultiLineTextInput = new Blockly.FieldMultilineInput('Add your request body here \n\n\n');
        this.appendDummyInput('body_input')
          .appendField(appendMultiLineTextInput, 'body_payload');
      },
    };
  }
}