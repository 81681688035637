const TOOLBOX = {
    "kind": "categoryToolbox",
    "contents": [
        {
            'kind': 'search',
            'name': 'Search',
            'contents': [],
            "cssConfig": {
                "container": "toolBoxSearch"
            }
        },
        {
            "kind": "category",
            "name": "Logic",
            "contents": [
                {
                    "kind": "block",
                    "type": "controls_if"
                },
                {
                    "kind": "block",
                    "type": "logic_compare"
                },
                {
                    "kind": "block",
                    "type": "logic_operation"
                },
                {
                    "kind": "block",
                    "type": "logic_negate"
                },
                {
                    "kind": "block",
                    "type": "logic_boolean"
                },
                {
                    "kind": "block",
                    "type": "logic_null"
                },
                {
                    "kind": "block",
                    "type": "logic_ternary"
                }
            ],
            "categorystyle": "logic_category"
        },
        {
            "kind": "category",
            "name": "Loops",
            "contents": [
                {
                    "kind": "block",
                    "type": "controls_repeat_ext",
                    "inputs": {
                        "TIMES": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "10"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "controls_whileUntil"
                },
                {
                    "kind": "block",
                    "type": "controls_for",
                    "inputs": {
                        "FROM": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "1"
                                }
                            }
                        },
                        "TO": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "10"
                                }
                            }
                        },
                        "BY": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "1"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "controls_forEach"
                },
                {
                    "kind": "block",
                    "type": "controls_flow_statements"
                }
            ],
            "categorystyle": "loop_category"
        },
        {
            "kind": "category",
            "name": "Math",
            "contents": [
                {
                    "kind": "block",
                    "type": "math_number",
                    "fields": {
                        "NUM": "123"
                    }
                },
                {
                    "kind": "block",
                    "type": "math_arithmetic",
                    "inputs": {
                        "A": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "1"
                                }
                            }
                        },
                        "B": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "1"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "math_single",
                    "inputs": {
                        "NUM": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "9"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "math_constant"
                },
                {
                    "kind": "block",
                    "type": "math_number_property",
                    "inputs": {
                        "NUMBER_TO_CHECK": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "math_round",
                    "inputs": {
                        "NUM": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "3.1"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "math_on_list"
                },
                {
                    "kind": "block",
                    "type": "math_modulo",
                    "inputs": {
                        "DIVIDEND": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "64"
                                }
                            }
                        },
                        "DIVISOR": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "10"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "math_constrain",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "50"
                                }
                            }
                        },
                        "LOW": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "1"
                                }
                            }
                        },
                        "HIGH": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "100"
                                }
                            }
                        }
                    }
                }

            ],
            "categorystyle": "math_category"
        },
        {
            "kind": "category",
            "name": "Text",
            "contents": [
                {
                    "kind": "block",
                    "type": "text"
                },
                {
                    "kind": "block",
                    "type": "text_join"
                },
                {
                    "kind": "block",
                    "type": "text_multiline"
                },
                {
                    "kind": "block",
                    "type": "text_append",
                    "inputs": {
                        "TEXT": {
                            "block": {
                                "type": "text"
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_length",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_isEmpty",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": null
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_indexOf",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{textVariable}"
                                }
                            }
                        },
                        "FIND": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_charAt",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{textVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_getSubstring",
                    "inputs": {
                        "STRING": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{textVariable}"
                                }
                            }
                        }
                    },
                },
                {
                    "kind": "block",
                    "type": "text_changeCase",
                    "inputs": {
                        "TEXT": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_reverse",
                    "inputs": {
                        "TEXT": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_count",
                    "inputs": {
                        "SUB": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "TEXT": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_replace",
                    "inputs": {
                        "FROM": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "TO": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "TEXT": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "text_trim",
                    "inputs": {
                        "TEXT": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": "abc"
                                }
                            }
                        }
                    }
                }
            ],
            "categorystyle": "text_category"
        },
        {
            "kind": "category",
            "name": "Lists",
            "contents": [
                {
                    "kind": "block",
                    "type": "lists_create_with",
                    "extraState": {
                        "itemCount": "0"
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_create_with"
                },
                {
                    "kind": "block",
                    "type": "lists_repeat",
                    "inputs": {
                        "NUM": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "5"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_length"
                },
                {
                    "kind": "block",
                    "type": "lists_isEmpty"
                },
                {
                    "kind": "block",
                    "type": "lists_indexOf",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_getIndex",
                    "inputs": {
                        "VALUE": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_setIndex",
                    "inputs": {
                        "LIST": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_getSublist",
                    "inputs": {
                        "LIST": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_split",
                    "inputs": {
                        "DELIM": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ","
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_reverse",
                    "inputs": {
                        "LIST": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "lists_sort"
                }
            ],
            "categorystyle": "list_category"
        },
        {
            "kind": "category",
            "name": "Variables",
            "contents": [],
            "custom": "VARIABLE",
            "categorystyle": "variable_category"
        },
        {
            "kind": "category",
            "name": "Functions",
            "contents": [],
            "custom": "PROCEDURE",
            "categorystyle": "procedure_category"
        },
        {
            "kind": "category",
            "name": "Custom Primitives",
            "contents": [
                {
                    "kind": "block",
                    "type": "marker"
                },
                {
                    "kind": "block",
                    "type": "sequence_system"
                },
                {
                    "kind": "block",
                    "type": "sequencer"
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_type",
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_condition",
                },
                {
                    "kind": "block",
                    "type": "haystack_logical_operator",
                },
                {
                    "kind": "block",
                    "type": "haystack_query_condition",
                },
                {
                    "kind": "block",
                    "type": "haystack_not_operator"
                },
                {
                    "kind": "block",
                    "type": "getCurrentHisValue",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getHistorizedValue",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        },
                        "INTERVAL": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "30"
                                }
                            }
                        },
                    }
                },
                {
                    "kind": "block",
                    "type": "getHistorizedValueExcludeMins",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        },
                        "INTERVAL": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "30"
                                }
                            }
                        },
                        "EXCLUDE_INTERVAL": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "5"
                                }
                            }
                        },
                    }
                },
                {
                    "kind": "block",
                    "type": "gethistorizedNVariant",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getHistorizedValueFromDateRange",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "gethistorizedDateTimeVariant",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getAggregatedValues",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getAggregatedValuesFromDateRange",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getAggregatedValuesFromDateTimeVariant",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getWrtiablePointValue",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "pointwrite",
                    "inputs": {
                        "value": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        },
                        "id": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "point_write_null",
                    "inputs":
                    {
                        "id": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "hisWrite",
                    "inputs": {
                        "value": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        },
                        "id": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "getKeyValue",
                    "inputs": {
                        "item": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "logger"
                },
                {
                    "kind": "block",
                    "type": "comment"
                },
                {
                    "kind": "block",
                    "type": "create_persistent_variable",
                    "inputs": {
                        "VARIABLE_NAME": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "VARIABLE_VALUE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "set_persistent_variable",
                    "inputs": {
                        "VARIABLE_NAME": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "get_persistent_variable",
                    "inputs": {
                        "VARIABLE_NAME": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "compare_date_inbetween"
                },
                {
                    "kind": "block",
                    "type": "date_time_format",
                },
                {
                    "kind": "block",
                    "type": "object_create"
                },
                {
                    "kind": "block",
                    "type": "xml_to_json"
                },
                {
                    "kind": "block",
                    "type": "date_format_converter"
                }
            ],
            "categorystyle": "custom_category"
        },
        {
            "kind": "category",
            "name": "Custom Functions",
            "contents": [
                {
                    "kind": "block",
                    "type": "getCurrentValueMany",
                    "inputs": {
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "pointWriteValueMany",
                    "inputs":
                    {
                        "value": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        },
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "hisWriteValueMany",
                    "inputs":
                    {
                        "value": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        },
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "writeValueandHistorizeMany",
                    "inputs":
                    {
                        "value": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "0"
                                }
                            }
                        },
                        "PointID": {
                            "block": {
                                "type": "variables_get",
                                "fields": {
                                    "VAR": "{listVariable}"
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_condition_pre_defined",
                    "inputs": {
                        "Query_condition": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_condition_pre_defined_equip",
                    "inputs": {
                        "Query_condition": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_condition_pre_defined_device",
                    "inputs": {
                        "Query_condition": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "haystack_query_with_condition_pre_defined_current_value",
                    "inputs": {
                        "Query_condition": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            ],
            "categorystyle": "custom_functions"
        },
        {
            "kind": "category",
            "name": "Alerts",
            "contents": [
                {
                    "kind": "block",
                    "type": "alertWithRef",
                    "inputs": {
                        "ALERT_REFERENCE": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "ALERT_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "ALERT_NOTIFICATION_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "alertWithOffset",
                    "inputs": {
                        
                        "ALERT_REFERENCE": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "ALERT_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "ALERT_NOTIFICATION_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                    }
                },
                {
                    "kind": "block",
                    "type": "alertHystersis",
                    "inputs": {
                        "ALERT_REFERENCE": {
                            "block": {
                                "type": "getKeyValue",
                                "inputs": {
                                    "item": {
                                        "block": {
                                            "type": "variables_get",
                                            "fields": {
                                                "VAR": "{listVariable}"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "ALERT_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "ALERT_NOTIFICATION_MESSAGE": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "ALERT_TRIGGER_UPPER_LIMIT": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "78"
                                }
                            }
                        },
                        "ALERT_FIX_UPPER_LIMIT": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "75"
                                }
                            }
                        },
                        "ALERT_TRIGGER_LOWER_LIMIT": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "68"
                                }
                            }
                        },
                        "ALERT_FIX_LOWER_LIMIT": {
                            "block": {
                                "type": "math_number",
                                "fields": {
                                    "NUM": "72"
                                }
                            }
                        },
                    }
                },
            ],
            "categorystyle": "alerts_category"
        },
        {
            "kind": "category",
            "name": "Unit Conversions",
            "contents": [{
                    "kind": "block",
                    "type": "temp_conversion"
                },
                {
                    "kind": "block",
                    "type": "angular_velocity_conversion"
                },
                {
                    "kind": "block",
                    "type": "velocity_conversion"
                },
                {
                    "kind": "block",
                    "type": "area_conversion"
                },
                {
                    "kind": "block",
                    "type": "volume_conversion"
                },
                {
                    "kind": "block",
                    "type": "enthalpy_conversion"
                },
                {
                    "kind": "block",
                    "type": "power_conversion"
                },
                {
                    "kind": "block",
                    "type": "force_conversion"
                },
                {
                    "kind": "block",
                    "type": "energy_conversion"
                },
                {
                    "kind": "block",
                    "type": "flow_conversion"
                },
                {
                    "kind": "block",
                    "type": "pressure_conversion"
                }
            ],
            "categorystyle": "unit_conversion_category"
        },
        {
            "kind": "category",
            "name": "Sequence Templates",
            "contents": [{
                "kind": "block",
                "type": "template_num_to_num"
            },
            {
                "kind": "block",
                "type": "template_num_to_bool"
            },
            {
                "kind": "block",
                "type": "template_75f_10kThermistor_type2_to_temp"
            },
            {
                "kind": "block",
                "type": "template_10kThermistor_type2_to_temp"
            },
            {
                "kind": "block",
                "type": "template_10kThermistor_type3_to_temp"
            },
            {
                "kind": "block",
                "type": "template_1kThermistor_to_temp"
            }
            ],
            "categorystyle": "templates_category"
        },
        {
            "kind": "category",
            "name": "API Integration",
            "contents": [
            {
                "kind": "block",
                "type": "http_request_block_with_input",
                "inputs": {
                    "request_url": {
                        "block": {
                            "type": "text",
                            "fields": {
                                "TEXT": ""
                            }
                        }
                    }
                }
            },
            {
                "kind": "block",
                "type": "http_request_header"
            },
            {
                "kind": "block",
                "type": "http_request_body"
            }
            ],
            "categorystyle": "api_request_category"
        },
        {
            "kind": "category",
            "name": "MQTT",
            "contents": [
                {
                    "kind": "block",
                    "type": "mqtt_subscribe",
                    "inputs": {
                        "brokerUrl": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "username": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "password": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "topic": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                    }
                },
                {
                    "kind": "block",
                    "type": "mqtt_publish",
                    "inputs": {
                        "brokerUrl": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "username": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "password": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "topic": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        },
                        "message": {
                            "block": {
                                "type": "text",
                                "fields": {
                                    "TEXT": ""
                                }
                            }
                        }
                    }
                },
                {
                    "kind": "block",
                    "type": "mqtt_get",
                },
                {
                    "kind": "block",
                    "type": "mqtt_get_topic_data",
                },
            ],
            "categorystyle": "mqtt_category"
        }
    ]
}
export default TOOLBOX;