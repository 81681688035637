import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SiteSequencerService } from 'src/app/components/site-sequencer/services/site-sequencer.service';

@Component({
  selector: 'app-edit-and-delete-confirmation-modal',
  templateUrl: './edit-and-delete-confirmation-modal.component.html',
  styleUrls: ['./edit-and-delete-confirmation-modal.component.scss']
})
export class EditAndDeleteConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<EditAndDeleteConfirmationModalComponent>,
    public sequencerService: SiteSequencerService
  ) { }
  title: any;
  subTitle: any;
  type = 'confirm';
  confirmBtnText = 'Confirm';
  showConfirmIcon = false;
  isFixedHeight!: boolean;
  sequenceFunction: any;
  isCanvasAction!: boolean;


  ngOnInit() {    
    if(this.sequenceFunction) {
      this.sequenceFunction['likeCount'] = this.sequenceFunction.likeCount * this.sequencerService.getCountMultiplier();
      this.sequenceFunction['eventLabel'] = (this.sequenceFunction.eventType === 'edit' ? 'Editing' : (this.sequenceFunction.eventType === 'delete' ? 'Deleting' : this.sequenceFunction.eventType));
      this.sequenceFunction['bookmarkText'] = this.sequenceFunction.bookmarkCount === 1 ? 'individual' : 'individuals';
      this.sequenceFunction['likeText'] = this.sequenceFunction.likeCount === 1 ? 'like' : 'likes';
      this.sequenceFunction['siteText'] = this.sequenceFunction.siteCount === 1 ? 'site' : 'sites';
      this.isCanvasAction ? this.sequenceFunction['sequenceText'] = this.sequenceFunction.sequenceCount === 1 ? 'sequence' : 'sequences' : '';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onBtnAction(event :any) {
    this.dialogRef.close(event);
  }

}
