import { Variables } from 'blockly';
import * as python from 'blockly/python'

export const SEQUENCE_RUN_STARTUP_HEADER_BEGIN = '# BEGIN_SEQUENCE_RUN_STARTUP';
export const SEQUENCE_RUN_STARTUP_HEADER_END = '# END_SEQUENCE_RUN_STARTUP';

export const SEQUENCE_RUN_CONTINUOUS_HEADER_BEGIN = '# BEGIN_SEQUENCE_RUN_CONTINUOUS';
export const SEQUENCE_RUN_CONTINUOUS_HEADER_END = '# END_SEQUENCE_RUN_CONTINUOUS';

export var sequence_run_rate_seconds = 5;
const LOW_CODE_PRINT_TAG = 'LC: ';

export var sequenceRunRateMs = 5000;
export var runStartupPythonFun = `runStartup`;
export var runContinuousPythonFun = `runContinuous`;

export class DeviceCodeGenerate {
  static addCodeGenerators() {
    try {
        function functionNameValidator(name: string) {
          return name.replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_]/g, '_');
        }
        function generateGlobalString (block:any, generator:any) {
          // The following code was taken from Blockly native python generator for functions
          // https://github.com/google/blockly/blob/master/generators/python/procedures.ts
        
          const globals = [];
          const workspace = block.workspace;
          const usedVariables = Variables.allUsedVarModels(workspace) || [];
          for (const variable of usedVariables) {
            const varName = variable.name;
            // getVars returns parameter names, not ids, for procedure blocks
            if (!block.getVars().includes(varName)) {
              globals.push(generator.getVariableName(varName));
            }
          }
          // Add developer variables.
          const devVarList = Variables.allDeveloperVariables(workspace);
          for (let i = 0; i < devVarList.length; i++) {
            const nameDB = generator.nameDB_ ? generator.nameDB_ : null;
            const name = nameDB ? nameDB.getName(devVarList[i], 'DEVELOPER_VARIABLE') : null;
            if (name) {
              globals.push(name);
            }
          }
        
          const globalString = globals.length
            ? generator.INDENT + 'global ' + globals.join(', ') + '\n'
            : '';
        
          return globalString;
        }

        python.pythonGenerator.forBlock['sequence_device'] = function (block: any, generator: any) {
          var markerName = block.getFieldValue('markername');
          var sequenceCode = generator.statementToCode(block, 'CODE');
          sequenceCode = generator.addLoopTrap(sequenceCode, block);
          var prefixCode = `def ${markerName}():`
          var suffixCode = `${markerName}()`;
          var code = `${prefixCode}\n${sequenceCode}\n${suffixCode}\n`;
          return code;
        };

        python.pythonGenerator.forBlock['sequencer'] = function (block: any, generator: any) {
          // Search the text for a substring.
          var sequencerName = block.getFieldValue('seqFunctionName');
          var sequencerCode = generator.statementToCode(block, 'CODE');
          // Ensure something is populated inside function
          if(!sequencerCode) {
            sequencerCode = generator.PASS;
          }

           // add a 'global' statement for every variable that is not shadowed by a local parameter
          // See discussion here for why this is relevant, https://github.com/google/blockly/issues/3728
          const globalString = generateGlobalString(block, generator);

          sequencerCode = generator.addLoopTrap(sequencerCode, block);
          var code = `\ndef ${functionNameValidator(sequencerName)}():\n${globalString}${sequencerCode}\n`;
          return code;
        };

        python.pythonGenerator.forBlock['sequence_run_startup'] = function(block:any, generator:any) {
          var statements_sequence = generator.statementToCode(block, 'sequence');
      
          // Ensure something is populated inside function
          if(!statements_sequence) {
            statements_sequence = generator.PASS;
          }
      
          // add a 'global' statement for every variable that is not shadowed by a local parameter
          // See discussion here for why this is relevant, https://github.com/google/blockly/issues/3728
          const globalString = generateGlobalString(block, generator);
      
          // Note, globalString contains '/n', therefore \n is not needed between globalString and statements_sequence
          var code = `def ${runStartupPythonFun}():\n${globalString}${statements_sequence}\n`;
      
          return code;
        };

        python.pythonGenerator.forBlock['sequence_run_continuous'] = function(block:any, generator:any) {
          var value_run_rate = generator.valueToCode(block, 'run_rate', python.Order.ATOMIC);
          var statements_sequence = generator.statementToCode(block, 'sequence');
        
          // Ensure something is populated inside function
          if(!statements_sequence) {
            statements_sequence = generator.PASS;
          }
      
          // Convert seconds to milliseconds
          sequenceRunRateMs = value_run_rate * 1000;
      
          // add a 'global' statement for every variable that is not shadowed by a local parameter
          // See discussion here for why this is relevant, https://github.com/google/blockly/issues/3728
          const globalString = generateGlobalString(block, generator);
      
          // Note, globalString contains '/n', therefore \n is not needed between globalString and statements_sequence
          var code = `def ${runContinuousPythonFun}():\n${globalString}${statements_sequence}\n`;
      
          return code;
        };
        

        python.pythonGenerator.forBlock['print_string'] = function(block: any, generator: any,) {
          var value_string_to_print = generator.valueToCode(block, 'string_to_print', python.Order.ATOMIC);
      
          // Prepend the string with a tag to indicate that it is a low code block
          var code = `print('${LOW_CODE_PRINT_TAG}' + str(${value_string_to_print}))\n`;
        
          //var code = `print(${value_string_to_print})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['conn_mod_set_relay_output'] = function(block: any, generator: any) {
          var value_relay_number = generator.valueToCode(block, 'relay_number', python.Order.ATOMIC);
          var value_relay_state = generator.valueToCode(block, 'relay_state', python.Order.ATOMIC);
      
          var code = `connmod.SetRelayOutput(${value_relay_number}, ${value_relay_state})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['conn_mod_relay_output_number'] = function(block: any, generator: any) {
          var dropdown_relay_output_number = block.getFieldValue('relay_output_number');
      
          // Assemble python into code variable.
          var code = Number(dropdown_relay_output_number);
          // Change ORDER_NONE to the correct strength.
          return [code, python.Order.ATOMIC];
        };

        python.pythonGenerator.forBlock['conn_mod_relay_output_state'] = function(block: any, generator: any) {
          var dropdown_relay_output_state = block.getFieldValue('relay_output_state');
      
          // Assemble python into code variable.
          var code = Number(dropdown_relay_output_state);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_set_analog_output'] = function(block: any, generator:any) {
          var value_aout_number = generator.valueToCode(block, 'aout_number', python.Order.ATOMIC);
          var value_aout_value = generator.valueToCode(block, 'aout_value', python.Order.ATOMIC);
      
          var code = `connmod.SetAnalogOutput(${value_aout_number}, ${value_aout_value})\n`;
          return code;
        };

        python.pythonGenerator.forBlock['conn_mod_analog_output_number'] = function(block:any, generator:any) {
          var dropdown_analog_output_number = block.getFieldValue('analog_output_number');
      
          var code = Number(dropdown_analog_output_number);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_analog_output_value'] = function(block:any, generator:any) {
          var number_analog_output_value = block.getFieldValue('analog_output_value');
      
          var code = Number(number_analog_output_value);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_sensor_number'] = function(block:any, generator:any) {
          var dropdown_sensor_number = block.getFieldValue('sensor_number');
      
          var code = Number(dropdown_sensor_number);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['sensor_bus_sensor_type'] = function(block:any, generator:any) {
          var dropdown_sensor_type = block.getFieldValue('sensor_type');
      
          var code = Number(dropdown_sensor_type);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_get_sensor_bus_value'] = function(block:any, generator:any) {
          var value_sensor_number = generator.valueToCode(block, 'sensor_number', python.Order.ATOMIC);
          var value_sensor_type = generator.valueToCode(block, 'sensor_type', python.Order.ATOMIC);
      
          var code = `connmod.GetSensorBusValue(${value_sensor_number}, ${value_sensor_type})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_universal_input_number'] = function(block:any, generator:any) {
          var dropdown_channel_number = block.getFieldValue('channel_number');
      
          var code = Number(dropdown_channel_number);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_universal_input_mode'] = function(block:any, generator:any) {
          var dropdown_uin_mode = block.getFieldValue('uin_mode');
      
          var code = dropdown_uin_mode;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_get_universal_input_value'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
      
          var code = `connmod.GetUniversalInputValue(${value_uin_number})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_get_universal_input_mode'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
      
          var code = `connmod.GetUniversalInputMode(${value_uin_number})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_set_universal_input_mode'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
          var value_uin_mode = generator.valueToCode(block, 'uin_mode', python.Order.ATOMIC);
      
          var code = `connmod.SetUniversalInputMode(${value_uin_number}, ${value_uin_mode})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['sequence_delay_milliseconds'] = function(block:any, generator:any) {
          var value_delay_ms = generator.valueToCode(block, 'delay_ms', python.Order.ATOMIC);
          var code = `connmod.DelayMs(${value_delay_ms})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['sequence_enter'] = function(block:any, generator:any) {
          var code = `connmod.EnterSequence()\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['sequence_exit'] = function(block:any, generator:any) {
          var code = `connmod.ExitSequence()\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['universal_input_standard_sensor_types'] = function(block:any, generator:any) {
          var dropdown_sensor_type = block.getFieldValue('sensor_type');
          var code = dropdown_sensor_type;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['universal_input_get_mode_from_standard_sensor_type'] = function(block: any, generator:any) {
          var value_sensor_type_index = generator.valueToCode(block, 'sensor_type_index', python.Order.ATOMIC);
      
          var code = `uniInputMapping.GetSensorModeFromIndex(${value_sensor_type_index})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['universal_input_convert_value_from_standard_sensor_type'] = function(block:any, generator:any) {
          var value_uin_value = generator.valueToCode(block, 'uin_value', python.Order.ATOMIC);
          var value_sensor_type_index = generator.valueToCode(block, 'sensor_type_index', python.Order.ATOMIC);
      
          var code = `uniInputMapping.ConvertValueFromIndex(${value_uin_value}, ${value_sensor_type_index})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_read_universal_input_mapping_modbus_register'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
      
          var code = `connmod.ReadUniversalInputMappingConfigHoldingReg(${value_uin_number})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_write_universal_input_modbus_register'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
          var value_uin_value = generator.valueToCode(block, 'uin_value', python.Order.ATOMIC);
      
          var code = `connmod.WriteUniversalInputMappedValInputReg(${value_uin_number}, ${value_uin_value})\n`;
          return code;
        };
        python.pythonGenerator.forBlock['conn_mod_read_relay_output_modbus_register'] = function(block:any, generator:any) {
          var value_relay_number = generator.valueToCode(block, 'relay_number', python.Order.ATOMIC);
          var code = `connmod.ReadRelayOutputMappedValHoldingReg(${value_relay_number})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_read_analog_output_modbus_register'] = function(block:any, generator:any) {
          var value_aout_number = generator.valueToCode(block, 'aout_number', python.Order.ATOMIC);
          var code = `connmod.ReadAnalogOutputMappedValHoldingReg(${value_aout_number})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_configure_home_screen_input'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
          var value_input_name = generator.valueToCode(block, 'input_name', python.Order.ATOMIC);
          var value_input_units = generator.valueToCode(block, 'input_units', python.Order.ATOMIC);
          var value_input_val = generator.valueToCode(block, 'input_val', python.Order.ATOMIC);
          var code = `connmod.ConfigureHomeScreenInput(${value_uin_number}, ${value_input_name}, ${value_input_units})\nconnmod.UpdateHomeScreenInput(${value_uin_number}, ${value_input_val})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['conn_mod_update_home_screen_input'] = function(block:any, generator:any) {
          var value_uin_number = generator.valueToCode(block, 'uin_number', python.Order.ATOMIC);
          var value_input_val = generator.valueToCode(block, 'input_val', python.Order.ATOMIC);
          var code = `connmod.UpdateHomeScreenInput(${value_uin_number}, ${value_input_val})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['universal_input_get_name_from_standard_sensor_type'] = function(block: any, generator:any) {
          var value_sensor_type_index = generator.valueToCode(block, 'sensor_type_index', python.Order.ATOMIC);
          var code = `uniInputMapping.GetSensorNameFromIndex(${value_sensor_type_index})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['universal_input_get_units_from_standard_sensor_type'] = function(block:any, generator:any) {
          var value_sensor_type_index = generator.valueToCode(block, 'sensor_type_index', python.Order.ATOMIC);
          var code = `uniInputMapping.GetSensorUnitFromIndex(${value_sensor_type_index})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['pi_controller_process'] = function(block:any, generator:any) {
          var value_controller_number = generator.valueToCode(block, 'controller_number', python.Order.ATOMIC);
          var value_process_variable = generator.valueToCode(block, 'process_variable', python.Order.ATOMIC);
          var value_set_point = generator.valueToCode(block, 'set_point', python.Order.ATOMIC);
          var value_proportional_gain = generator.valueToCode(block, 'proportional_gain', python.Order.ATOMIC);
          var value_integral_gain = generator.valueToCode(block, 'integral_gain', python.Order.ATOMIC);
          var value_error_range = generator.valueToCode(block, 'error_range', python.Order.ATOMIC);
          var value_sample_time = generator.valueToCode(block, 'sample_time', python.Order.ATOMIC);
          var value_integration_time = generator.valueToCode(block, 'integration_time', python.Order.ATOMIC);
      
          var code = `connmod.PIDControllerProcess(${value_controller_number}, ${value_process_variable}, ${value_set_point}, ${value_proportional_gain}, ${value_integral_gain}, 0.0, ${value_error_range}, ${value_integration_time}, ${value_sample_time})\n`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['pi_controller_number'] = function(block:any, generator:any) {
          var dropdown_pi_controller_number = block.getFieldValue('pi_controller_number');
          var code = Number(dropdown_pi_controller_number);
          return [code, python.Order.ATOMIC];
        };

        python.pythonGenerator.forBlock['modbus_register_type'] = function(block:any, generator:any) {
          var dropdown_register_type = block.getFieldValue('register_type');
          var code = Number(dropdown_register_type);
          return [code, python.Order.ATOMIC];
        };
      
      
        python.pythonGenerator.forBlock['modbus_register_address'] = function(block:any, generator:any) {
          var number_address = block.getFieldValue('address');
          var code = Number(number_address);
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_configure_custom_modbus_register'] = function(block:any, generator:any) {
          var value_register_type = generator.valueToCode(block, 'register_type', python.Order.ATOMIC);
          var value_register_address = generator.valueToCode(block, 'register_address', python.Order.ATOMIC);
          var code = `connmod.ConfigureCustomModbusRegister(${value_register_type}, ${value_register_address})\n`;
          return code;
        };

        python.pythonGenerator.forBlock['conn_mod_read_modbus_register'] = function(block:any, generator:any) {
          var value_register_type = generator.valueToCode(block, 'register_type', python.Order.ATOMIC);
          var value_register_address = generator.valueToCode(block, 'register_address', python.Order.ATOMIC);
          var code = `connmod.ReadModbusRegister(${value_register_type}, ${value_register_address})`;
          return [code, python.Order.ATOMIC];
        };
      
        python.pythonGenerator.forBlock['conn_mod_write_modbus_register'] = function(block:any, generator:any) {
          var value_register_type = generator.valueToCode(block, 'register_type', python.Order.ATOMIC);
          var value_register_address = generator.valueToCode(block, 'register_address', python.Order.ATOMIC);
          var value_register_value = generator.valueToCode(block, 'register_value', python.Order.ATOMIC);
          var code = `connmod.WriteModbusRegister(${value_register_type}, ${value_register_address}, ${value_register_value})\n`;
          return code;
        };
      
        python.pythonGenerator.forBlock['conversion_scale_value'] = function(block:any, generator:any) {
          var value_input_value = generator.valueToCode(block, 'input_value', python.Order.ATOMIC);
          var value_input_value_min = generator.valueToCode(block, 'input_value_min', python.Order.ATOMIC);
          var value_input_value_max = generator.valueToCode(block, 'input_value_max', python.Order.ATOMIC);
          var value_output_value_min = generator.valueToCode(block, 'output_value_min', python.Order.ATOMIC);
          var value_output_value_max = generator.valueToCode(block, 'output_value_max', python.Order.ATOMIC);
          var code = `uniInputMapping.ScaleValue(${value_input_value}, ${value_input_value_min}, ${value_input_value_max}, ${value_output_value_min}, ${value_output_value_max})`;
          return [code, python.Order.ATOMIC];
        };

        python.pythonGenerator.forBlock['conn_mod_enable_fail_safe'] = function(block:any, generator:any) {
          var value_fail_safe_enable = generator.valueToCode(block, 'fail_safe_enable', python.Order.ATOMIC);
          var code = `connmod.EnableFailSafe(${value_fail_safe_enable})\n`;
          return code;
        };

        python.pythonGenerator.forBlock['bacnet_object_instance'] = function(block:any, generator:any) {
          var number_instance = block.getFieldValue('instance');
          var code = Number(number_instance);
          return [code, python.Order.ATOMIC];
        };
        
        
        python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_bi_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
          var value_active_text = generator.valueToCode(block, 'active_text', python.Order.ATOMIC);
          var value_inactive_text = generator.valueToCode(block, 'inactive_text', python.Order.ATOMIC);
      
          var code = `connmod.ConfigureBacnetBiObject(${value_instance}, ${value_name}, ${value_active_text}, ${value_inactive_text})\n`;
          return code;
        };
        
        
        
        python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_bv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
          var value_active_text = generator.valueToCode(block, 'active_text', python.Order.ATOMIC);
          var value_inactive_text = generator.valueToCode(block, 'inactive_text', python.Order.ATOMIC);
      
          var code = `connmod.ConfigureBacnetBvObject(${value_instance}, ${value_name}, ${value_active_text}, ${value_inactive_text})\n`;
          return code;
        };
        
        
        python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_msi_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
          var value_state_text = generator.valueToCode(block, 'state_text', python.Order.ATOMIC);
      
          var code = `connmod.ConfigureBacnetMsiObject(${value_instance}, ${value_name}, ${value_state_text}, len(${value_state_text}))\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_msv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
          var value_state_text = generator.valueToCode(block, 'state_text', python.Order.ATOMIC);
          var code = `connmod.ConfigureBacnetMsvObject(${value_instance}, ${value_name}, ${value_state_text}, len(${value_state_text}))\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_ai_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
          var code = `connmod.WriteBacnetAiObject(${value_instance}, ${value_value})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_av_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
          var code = `connmod.WriteBacnetAvObject(${value_instance}, ${value_value})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_read_custom_bacnet_av_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var code = `connmod.ReadBacnetAvObject(${value_instance})`;
          return [code, python.Order.ATOMIC];
        };
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_bi_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
      
          var code = `connmod.WriteBacnetBiObject(${value_instance}, ${value_value})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_bv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
      
          var code = `connmod.WriteBacnetBvObject(${value_instance}, ${value_value})\n`;
          return code;
        };
  
        python.pythonGenerator.forBlock['conn_mod_read_custom_bacnet_bv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var code = `connmod.ReadBacnetBvObject(${value_instance})`;
          return [code, python.Order.ATOMIC];
        };
        
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_msi_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
          var code = `connmod.WriteBacnetMsiObject(${value_instance}, ${value_value})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_write_custom_bacnet_msv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var value_value = generator.valueToCode(block, 'value', python.Order.ATOMIC);
          var code = `connmod.WriteBacnetMsvObject(${value_instance}, ${value_value})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['conn_mod_read_custom_bacnet_msv_object'] = function(block:any, generator:any) {
          var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
          var code = `connmod.ReadBacnetMsvObject(${value_instance})`;
          return [code, python.Order.ATOMIC];
        };
        
        python.pythonGenerator.forBlock['bacnet_units'] = function(block:any, generator:any) {
          var dropdown_unit = block.getFieldValue('unit');
          var code = Number(dropdown_unit);
          return [code, python.Order.ATOMIC];
        };
        
        python.pythonGenerator.forBlock['set_device_time'] = function(block:any, generator:any) {
          var number_hours = block.getFieldValue('hours');
          var number_minutes = block.getFieldValue('minutes');
          var dropdown_day = block.getFieldValue('day');
          // TODO: Assemble python into code variable.
          var code = `connmod.SetDeviceTime(${number_hours}, ${number_minutes}, ${dropdown_day})\n`;
          return code;
        };
        
        python.pythonGenerator.forBlock['get_device_time'] = function(block:any, generator:any) {
          // TODO: Assemble python into code variable.
          var code = `connmod.GetDeviceTime()\n`;
          // TODO: Change ORDER_NONE to the correct strength.
          return [code, python.Order.COLLECTION];
        };
        
          python.pythonGenerator.forBlock['sequence_time'] = function(block:any, generator:any) {
            var number_hours = block.getFieldValue('hours');
            var number_minutes = block.getFieldValue('minutes');
            var dropdown_day = block.getFieldValue('day');
            // TODO: Assemble python into code variable.
            var code = `[${number_hours}, ${number_minutes}, ${dropdown_day}]`;
            // TODO: Change ORDER_NONE to the correct strength.
            return [code, python.Order.COLLECTION];
          };

          
          python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_ai_object'] = function(block:any, generator: any) {
            var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
            var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
            var value_units = generator.valueToCode(block, 'units', python.Order.ATOMIC);
            var code = `connmod.ConfigureBacnetAiObject(${value_instance}, ${value_name}, ${value_units})\n`;
            return code;
          };
          
          python.pythonGenerator.forBlock['conn_mod_configure_custom_bacnet_av_object'] = function(block: any, generator: any) {
            var value_instance = generator.valueToCode(block, 'instance', python.Order.ATOMIC);
            var value_name = generator.valueToCode(block, 'name', python.Order.ATOMIC);
            var value_units = generator.valueToCode(block, 'units', python.Order.ATOMIC);
            var value_min_val = generator.valueToCode(block, 'min_val', python.Order.ATOMIC);
            var value_max_val = generator.valueToCode(block, 'max_val', python.Order.ATOMIC);
            var code = `connmod.ConfigureBacnetAvObject(${value_instance}, ${value_name}, ${value_units}, ${value_min_val}, ${value_max_val})\n`;
            return code;
          };

    }
    catch (e) {
      console.log("Error in adding code generators", e);
    }
  }
}
