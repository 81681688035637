import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private configService: ConfigurationService,
  ) { }
  /**
  * Formats the profile name.
  * @param profile - The profile name to be formatted.
  */
 userDetails: User = {};

  getFormattedProfileName(profile: string) {
    let isCarrier = this.configService.getConfig('isCarrier') || false;
    switch (profile) {
      case 'SYSTEM_DEFAULT':
        return 'Default System Profile';
      case 'SYSTEM_VAV_ANALOG_RTU':
      case 'vavFullyModulatingAhu':
          return 'VAV Fully Modulating AHU';
      case 'SYSTEM_VAV_STAGED_RTU':
      case 'vavStagedRtu':
          return 'VAV Staged RTU';
      case 'SYSTEM_VAV_HYBRID_RTU':
          return 'VAV Advanced Hybrid AHU';
      case 'SYSTEM_VAV_STAGED_VFD_RTU':
      case 'vavStagedRtuVfdFan':
      case 'vavStagedVfdRtu':
          return 'VAV Staged RTU with VFD Fan';
      case 'SYSTEM_DAB_ANALOG_RTU':
      case 'dabFullyModulatingAhu':
          return isCarrier ? 'VVT-C Fully Modulating AHU' : 'DAB Fully Modulating AHU';
      case 'SYSTEM_DAB_STAGED_RTU':
      case 'dabStagedRtu':
          return isCarrier ? 'VVT-C Staged RTU' : 'DAB Staged RTU';
      case 'SYSTEM_DAB_HYBRID_RTU':
          return isCarrier ? 'VVT-C Advanced Hybrid AHU' : 'DAB Advanced Hybrid AHU';
      case 'SYSTEM_DAB_STAGED_VFD_RTU':
      case 'dabStagedRtuVfdFan':
          return isCarrier ? 'VVT-C Staged RTU with VFD Fan' : 'DAB Staged RTU with VFD Fan';
      case 'dabExternalAHUController':
          return isCarrier ? 'VVT-C External AHU' : 'DAB External AHU';
      case 'vavExternalAHUController':
          return 'VAV External AHU';
      case 'vavAdvancedHybridAhuV2':
          return 'VAV Advanced Hybrid AHU v2';
      case 'dabAdvancedHybridAhuV2':
          return isCarrier? 'VVT-C Advanced Hybrid AHU v2' : 'Dab Advanced Hybrid AHU v2';
      default:
          return profile;
    }
  }

  getFormattedColumnName(columnName: string) {
    switch (columnName) {
      case 'deliveredDateTime':
        return 'Delivered Date & Time';
      case 'ackedDateTime':
        return 'Confirmed Receipt';
      case 'ccuName':
        return 'System';
      case 'requestTime':
          return 'Request Time';
      default:
        return columnName;
    }
  }

  getFormattedKeyForAlertBlock(key: string) {
    switch (key) {
      case 'ALERT_TITLE': 
        return 'title';
      case 'ALERT_MESSAGE':
        return 'message';
      case 'ALERT_SEVERITY':
        return 'severity';
      case 'ALERT_TYPE':
        return 'alertType';
      case 'ALERT_FIX_OFFSET':
        return 'fixOffset';
      case 'ALERT_TRIGGER_OFFSET':
        return 'triggerOffset';
      case 'SHOW_LOWER_LIMIT': 
        return 'lowerLimitEnabled';
      case 'SHOW_UPPER_LIMIT':
        return 'upperLimitEnabled';
      case 'ALERT_TRIGGER_UPPER_LIMIT_OFFSET':
        return 'upperLimitTriggerOffset';
      case 'ALERT_FIX_UPPER_LIMIT_OFFSET':
        return 'upperLimitFixOffset';
      case 'ALERT_TRIGGER_LOWER_LIMIT_OFFSET':
        return 'lowerLimitTriggerOffset';
      case 'ALERT_FIX_LOWER_LIMIT_OFFSET':
        return 'lowerLimitFixOffset';
      case 'alertWithOffset':
        return 'ALERT_BLOCK_OFFSET';
      case 'alertHystersis':
        return 'ALERT_BLOCK_OFFSET_WITH_LIMIT';
      default:
        return key;
    }
  }

  getDisplayId() {
    const loggedInUserDetails = this.userDetails;
    if (Object.keys(loggedInUserDetails)?.length) {
      return loggedInUserDetails.firstName + ' ' + loggedInUserDetails.lastName;
    } else {
      return null;
    }
  }

}
