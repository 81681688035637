export enum BlockColors {
  Logic_BLOCKS = '#587C9F',
  LOOP_BLOCKS = '#5BA55B',
  MATH_BLOCKS = '#5B67A5',
  TEXT_BLOCKS = '#5BA58C',
  LIST_BLOCKS = '#745BA5',
  VARIABLE_BLOCKS = '#A55B80',
  PROCEDURE_BLOCKS = '#AE3A74',
  VARIABLE_DYNAMIC_BLOCKS = '#525682',
  CUSTOM_FUNCTIONS = '#559256',
  UNIT_CONVERSION_BLOCKS = '#4B4C5A',
  TEMPLATE_BLOCKS = '#C35E5E',
  API_BLOCKS = '#9B5151',
  ALERTS_BLOCKS = '#D9534F',
  MQTT_BLOCKS = '#6082B6'
}

export const BlockTheme = {
  blockStyles: {
    logic_blocks: {
      colourPrimary: BlockColors.Logic_BLOCKS
    },
    loop_blocks: {
      colourPrimary: BlockColors.LOOP_BLOCKS
    },
    math_blocks: {
      colourPrimary: BlockColors.MATH_BLOCKS
    },
    text_blocks: {
      colourPrimary: BlockColors.TEXT_BLOCKS
    },
    list_blocks: {
      colourPrimary: BlockColors.LIST_BLOCKS
    },
    variable_blocks: {
      colourPrimary: BlockColors.VARIABLE_BLOCKS
    },
    procedure_blocks: {
      colourPrimary: BlockColors.PROCEDURE_BLOCKS
    },
    variable_dynamic_blocks: {
      colourPrimary: BlockColors.VARIABLE_DYNAMIC_BLOCKS
    },
    api_request_blocks: {
      colourPrimary: BlockColors.API_BLOCKS
    },
    alerts_blocks: {
      colourPrimary: BlockColors.ALERTS_BLOCKS
    },
    mqtt_blocks: {
      colourPrimary: BlockColors.MQTT_BLOCKS
    }
  }
}

export const categoryTheme = {
  categoryStyle: {
    "logic_category": {
      "colour": `${BlockColors.Logic_BLOCKS}`
    },
    "loop_category": {
      "colour": `${BlockColors.LOOP_BLOCKS}`
    },
    "math_category": {
      "colour": `${BlockColors.MATH_BLOCKS}`
    },
    "text_category": {
      "colour": `${BlockColors.TEXT_BLOCKS}`
    },
    "list_category": {
      "colour": `${BlockColors.LIST_BLOCKS}`
    },
    "variable_category": {
      "colour": `${BlockColors.VARIABLE_BLOCKS}`
    },
    "procedure_category": {
      "colour": `${BlockColors.PROCEDURE_BLOCKS}`
    },
    "custom_category": {
      "colour": `${BlockColors.VARIABLE_DYNAMIC_BLOCKS}`
    },
    "custom_functions": {
      "colour": `${BlockColors.CUSTOM_FUNCTIONS}`
    },
    "unit_conversion_category": {
      "colour": `${BlockColors.UNIT_CONVERSION_BLOCKS}`
    },
    "templates_category": {
      "colour": `${BlockColors.TEMPLATE_BLOCKS}`
    },
    "api_request_category":
    {
      "colour": `${BlockColors.API_BLOCKS}`
    },
    "alerts_category": {
      "colour": `${BlockColors.ALERTS_BLOCKS}`
    },
    "mqtt_category": {
      "colour": `${BlockColors.MQTT_BLOCKS}`
    }
  }
}