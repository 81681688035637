import * as Blockly from 'blockly';

export class BlocklyValidators {
  private static validatorsAdded: boolean = false;

  static addValidators() {
    if(!this.validatorsAdded) {
      try {
        Blockly.Extensions.register(
          "unit_selection_validation",
          function (this: any) {
            this.setOnChange((event: Blockly.Events.Abstract) => {
              if (this.isInFlyout) {
                return;
              }
              if (
                event.type === Blockly.Events.CHANGE &&
                (event as Blockly.Events.BlockCreate).blockId === this.id
                      ) {
                        if (this.getFieldValue('FROM_UNIT') !== this.getFieldValue('TO_UNIT')) {
                          this.setWarningText(null);
                          this.setHighlighted(false);
                          this.isInErrorState = false;
                          
                        } else {
                          this.setWarningText('Conversion units cannot be same');
                          this.setHighlighted(true);
                          this.isInErrorState = true;
                        }
                      }
             
            });
          }
        );
        
        // Restrict block attachment type to a specific block type
        Blockly.Extensions.register('validate_block_input_type_number', 
          function (this: any) {
            const targetBlockTypes = ['alertWithOffset', 'alertHystersis'] // Block types here having number input fields
            if(targetBlockTypes.includes(this.type)) {
              // Field names here having number type
              const targetBlockFieldsWithNumberType = [
                'ALERT_TRIGGER_OFFSET', 'ALERT_FIX_OFFSET', 'ALERT_TRIGGER_UPPER_LIMIT_OFFSET', 
                'ALERT_FIX_UPPER_LIMIT_OFFSET', 'ALERT_TRIGGER_LOWER_LIMIT_OFFSET', 'ALERT_FIX_LOWER_LIMIT_OFFSET'
              ];
              this.setOnChange((event: Blockly.Events.Abstract) => {
                if(!this.isInFlyout) {
                  targetBlockFieldsWithNumberType.forEach((fieldName: string) => {
                    const blockHasField = this.getInput(fieldName);
                    if(blockHasField) {
                      const connectedBlock = this.getInput(fieldName).connection.targetBlock();
                      if(connectedBlock && connectedBlock.type !== 'math_number') {
                        connectedBlock.unplug();
                      }
                    }
                  });
                }
              });
            }
          }
        ),

        BlocklyValidators.validatorsAdded = true;
      }
      catch (e) {
        console.log("Error in adding code generators", e);
      }
    } else {
      console.log("Validators already added.");
    }
  }
}